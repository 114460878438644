import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import BoatOnComponent from './BoatOnComponent'
import styles from './Styles/BoatOnButtonCss'
import MButton from '@material-ui/core/Button'
import Fade from '@material-ui/core/Fade'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import ClearIcon from '@material-ui/icons/Clear'
import BoatOnBlock from './Blocks/BoatOnBlock'

class BoatOnButton extends BoatOnComponent {
    constructor(props) {
        super(props)

        this.state = {
            showDropDown: false,
        }
        this.myRef = React.createRef()

        this.showDropDown = this.showDropDown.bind(this)
        this.handleDropdownClick = this.handleDropdownClick.bind(this)
        this.handleCancel = this.handleCancel.bind(this)
    }

    showDropDown() {
        this.setState({ showDropDown: !this.state.showDropDown })
    }

    handleCancel(e) {
        e.stopPropagation()
        this.props.onCancel()
    }

    _renderButton(classe) {
        const {
            onClick = () => {},
            label = `Button`,
            classes,
            size = `medium`,
            dropDown = false,
            startIcon = null,
            href = null,
            endIcon = null,
            fullWidth = false,
            disabled = false,
            style = {},
            containerStyle = {},
            addProps = {},
            classNameOverride = {},
            selectedCount = null,
            isCancelActived = false,
            form = null,
            onCancel = null,
        } = this.props
        const { showDropDown } = this.state

        const fullWidthStyle = fullWidth ? { width: `100%` } : {}

        return (
            <div
                className={classNameOverride}
                style={{
                    border: `none`,
                    background: `rgba(0,0,0,0)`,
                    padding: 0,
                    margin: 0,
                    width: 'fit-content',
                    ...fullWidthStyle,
                    ...containerStyle,
                }}
            >
                <MButton
                    variant="contained"
                    className={
                        classe
                            ? `${classe} ${classNameOverride} ${
                                  disabled ? classes.disabled : null
                              }`
                            : `${classes.buttonPrimary} ${classNameOverride} ${
                                  disabled ? classes.disabled : null
                              }`
                    }
                    margin="normal"
                    key={1}
                    ref={this.myRef}
                    classes={{
                        // root: classes.muiButtonRoot,
                        endIcon: classes.icon,
                        label: classes.label,
                    }}
                    onClick={dropDown ? this.showDropDown : onClick}
                    size={size}
                    startIcon={startIcon}
                    href={href}
                    fullWidth={fullWidth}
                    disabled={disabled}
                    style={style}
                    type={form ? 'submit' : null}
                    form={form}
                    endIcon={
                        endIcon ? (
                            endIcon
                        ) : dropDown && showDropDown ? (
                            <KeyboardArrowUpIcon />
                        ) : dropDown && !showDropDown ? (
                            <KeyboardArrowDownIcon />
                        ) : isCancelActived && onCancel ? (
                            <ClearIcon
                                onClick={this.handleCancel}
                                style={{ marginLeft: '5px' }}
                            />
                        ) : null
                    }
                    {...addProps}
                >
                    <>
                        {label}
                        {selectedCount && (
                            <div className={classes.selectedCount}>
                                {selectedCount}
                            </div>
                        )}
                    </>
                </MButton>
                {this.state.showDropDown && this._getDropDown()}
            </div>
        )
    }

    handleDropdownClick(e) {
        const index = e?.currentTarget?.dataset?.index
        const { dropDown } = this.props

        this.showDropDown()
        if (index && dropDown[index]?.action) {
            dropDown[index].action()
        }
    }

    _getDropDown() {
        const {
            dropDown,
            containMenuWidth = true,
            anchorOrigin,
            transformOrigin,
        } = this.props
        if (!this.myRef.current) return

        return (
            <Menu
                key={2}
                id="fade-menu"
                anchorEl={this.myRef.current}
                keepMounted
                getContentAnchorEl={null}
                open={this.state.showDropDown}
                onClose={this.showDropDown}
                TransitionComponent={Fade}
                anchorOrigin={
                    anchorOrigin ?? {
                        vertical: 'bottom',
                        horizontal: 'center',
                    }
                }
                transformOrigin={
                    transformOrigin ?? {
                        vertical: 'top',
                        horizontal: 'center',
                    }
                }
                MenuListProps={{
                    style: {
                        padding: 0,
                    },
                }}
                PaperProps={{
                    style: {
                        marginTop: '5px',
                    },
                }}
            >
                {dropDown.map((e, i) =>
                    e.rightBlockIds ? (
                        <BoatOnBlock
                            blockIds={e.rightBlockIds}
                            children={
                                <MenuItem
                                    style={{
                                        width: containMenuWidth
                                            ? this.myRef.current.getBoundingClientRect()
                                                  .width
                                            : undefined,
                                        borderBottom:
                                            dropDown.length === i + 1
                                                ? `none`
                                                : `1px solid lightgray`,
                                        padding: `10px`,
                                    }}
                                    key={e.label}
                                    data-index={i}
                                    onClick={this.handleDropdownClick}
                                    disabled={e.disabled}
                                >
                                    {e.label}
                                </MenuItem>
                            }
                        />
                    ) : (
                        <MenuItem
                            style={{
                                width: this.myRef.current.getBoundingClientRect()
                                    .width,
                                borderBottom:
                                    dropDown.length === i + 1
                                        ? `none`
                                        : `1px solid lightgray`,
                                padding: `10px`,
                            }}
                            key={e.label}
                            data-index={i}
                            onClick={this.handleDropdownClick}
                            disabled={e.disabled}
                        >
                            {e.label}
                        </MenuItem>
                    ),
                )}
            </Menu>
        )
    }

    render() {
        const { type, classes } = this.props

        switch (type) {
            case 'primary':
                return this._renderButton()
            case 'secondary':
                return this._renderButton(classes.buttonSecondary)
            case 'cancel':
                return this._renderButton(classes.buttonCancel)
            case 'validate':
                return this._renderButton(classes.buttonValidate)
            case 'text':
                return this._renderButton(classes.buttonText)
            case 'light':
                return this._renderButton(classes.buttonLight)
            case 'error':
                return this._renderButton(classes.buttonError)
            default:
                return this._renderButton()
        }
    }
}

const Button = withStyles(styles)(BoatOnButton)
export { Button }
