import React from 'react'
import BoatOnComponent from '../../../common/BoatOnComponent'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import styles from './Styles/MovementTabCss'
import dictionary from './Dictionary/MovementTabDico'
import Typography from '@material-ui/core/Typography'
import AttachFile from '@material-ui/icons/AttachFile'
import Delete from '@material-ui/icons/Delete'
import Edit from '@material-ui/icons/Edit'
import BoatOnImageRender from '../../../common/BoatOnImageRender'
import BoatOnModalCore from '../../../common/BoatOnModalCore'
import BoatOnModal from '../../../common/BoatOnModal'
import { typesActions } from '../../../../actions/types.actions'
import { getImageToDisplay } from '../../../../actions/bob.actions'
import {
    Popover,
    Button as ButtonMui,
    IconButton,
    Checkbox,
} from '@material-ui/core'
import BoatOnRenderDate from '../../../common/UsefullComponents/BoatOnRenderDate'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import BoatOnBlock from '../../../common/Blocks/BoatOnBlock'

class MovementTab extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {
            openDoc: null,
            openModal: false,
            displayNewFiles: { event: [] },
            renderPopup: false,
            anchorPopup: null,
            transacPopup: null,
        }

        this.handleClosePopup = this.handleClosePopup.bind(this)
        this._renderPopup = this._renderPopup.bind(this)
        this.handleOpenPopOverMore = this.handleOpenPopOverMore.bind(this)

        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.handleOpenModalMyDocument = this.handleOpenModalMyDocument.bind(
            this,
        )
        this.renderSpendings = this.renderSpendings.bind(this)
    }

    handleOpenModalMyDocument(document) {
        this.setState({ openModal: 'showDocs', openDoc: document })
    }

    handleCloseModal() {
        this.setState({
            openModal: false,
            openDoc: null,
        })
    }

    toRealTransaction(event) {
        const { eventTypes } = this.props
        const eventType = typesActions.getEventTypeById(
            eventTypes,
            event.eventType.id,
        )
        if (eventTypes) {
            if (eventType && eventType.spending && !eventType.earning) {
                return Math.abs(event.transaction.amountIt) * -1
            } else if (eventType && eventType.earning && !eventType.spending) {
                return Math.abs(event.transaction.amountIt)
            } else if (eventType && eventType.earning && eventType.spending)
                return event.transaction.amountIt
        }
        return Math.abs(event.transaction.amountIt)
    }

    renderDocuments() {
        const { openDoc } = this.state

        return (
            <BoatOnModalCore
                body={
                    <BoatOnImageRender
                        vertical
                        multi
                        bottomImage
                        imgs={getImageToDisplay(
                            openDoc,
                            this.state.displayNewFiles.event,
                        )}
                    />
                }
            />
        )
    }

    handleClosePopup() {
        this.setState({
            renderPopup: false,
            anchorPopup: null,
            transacPopup: null,
        })
    }

    _renderPopup() {
        const { classes, documents = [] } = this.props
        const { transacPopup } = this.state

        return (
            <Popover
                id={'popover'}
                open={this.state.renderPopup}
                anchorEl={this.state.anchorPopup}
                onClose={this.handleClosePopup}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div className={classes.iconsMobile}>
                    <BoatOnBlock
                        blockIds={[11, 13]}
                        onlyOneId={true}
                        addedCondition={id =>
                            (id === 11 &&
                                transacPopup?.eventType?.earning === true) ||
                            (id === 13 &&
                                transacPopup?.eventType?.spending === true)
                        }
                        children={
                            <ButtonMui
                                onClick={() => {
                                    this.handleClosePopup()
                                    this.props.edit(transacPopup, documents)
                                }}
                                className={classes.actionMobile}
                                startIcon={<Edit />}
                            >
                                {this.displayText(`edit`)}
                            </ButtonMui>
                        }
                    />

                    <BoatOnBlock
                        blockIds={15}
                        children={
                            <ButtonMui
                                onClick={() => {
                                    if (
                                        window.confirm(
                                            this.displayText('validateDelete'),
                                        )
                                    ) {
                                        this.props.delete(transacPopup)
                                    }
                                }}
                                className={classes.actionMobile}
                                startIcon={<Delete />}
                            >
                                {this.displayText(`supprimerTransaction`)}
                            </ButtonMui>
                        }
                    />
                </div>
            </Popover>
        )
    }

    handleOpenPopOverMore(e, event) {
        this.setState({
            transacPopup: event,
            renderPopup: true,
            anchorPopup: e.currentTarget,
        })
    }

    _getDetailAndChekupTitle(event) {
        const { checkup, detail } = event
        if (checkup)
            return checkup?.checkupType
                ? this.displayTextApi(checkup.checkupType.translation)
                : checkup?.userCheckup
                ? checkup.userCheckup.name
                : event.title
        else if (detail) {
            const part = detail.part
            const equipment = detail.equipment
            if (part) {
                if (part.userPart) return part.userPart.name
                else if (part.partType)
                    return this.displayTextApi(part.partType.translation)
            } else if (equipment) {
                if (equipment.userEquipment) {
                    return equipment.userEquipment.name
                } else if (equipment.equipmentType)
                    return this.displayTextApi(
                        equipment.equipmentType.translation,
                    )
            }
        }
        return event.title
    }

    renderSpendings(transaction, index) {
        const { classes, eventTypes, checkedExpenses } = this.props

        const formatter = new Intl.NumberFormat('fr-FR', {
            style: 'currency',
            currency: 'EUR',
        })

        const eventType = typesActions.getEventTypeById(
            eventTypes,
            transaction.eventType.id,
        )

        const realTransaction = this.toRealTransaction(transaction)

        return (
            <div
                className={`${classes.line} ${
                    index % 2 === 1 ? `` : classes.blueLine
                }`}
                key={index}
            >
                <Checkbox
                    color="primary"
                    checked={checkedExpenses.indexOf(transaction.id) >= 0}
                    onClick={() => this.props.onMovementCheck(transaction.id)}
                    classes={{ root: classes.checkbox }}
                />
                <div className={classes.section}>
                    <div className={classes.adaptableSection}>
                        <div className={classes.dates}>
                            {transaction?.delimitedDate?.startDate && (
                                <BoatOnRenderDate
                                    date={
                                        new Date(
                                            transaction.delimitedDate.startDate,
                                        )
                                    }
                                    classesOverride={{
                                        root: classes.renderDateRoot,
                                    }}
                                />
                            )}
                        </div>
                        <img
                            src={
                                eventType.photo
                                    ? require(`images/EventBobIcon/${eventType.photo}`)
                                    : ''
                            }
                            alt={this.displayTextApi(eventType.translation)}
                            className={classes.icon}
                        />
                        <div className={classes.titleAndType}>
                            <Typography
                                className={classes.subtitle}
                                variant="body1"
                            >
                                {this._getDetailAndChekupTitle(transaction)}
                            </Typography>

                            <Typography
                                className={classes.mobileTypeAndAmountContainer}
                            >
                                {this.displayTextApi(eventType.translation)}
                                <span
                                    className={[
                                        classes.mobileOnly,
                                        classes.amount,
                                        realTransaction > 0
                                            ? classes.green
                                            : classes.red,
                                    ].join(' ')}
                                >
                                    {formatter.format(realTransaction)}
                                </span>
                            </Typography>
                        </div>
                        <BoatOnBlock
                            blockIds={[11, 13, 14, 15]}
                            onlyOneId={true}
                            addedCondition={id =>
                                id !== 14 ||
                                (id === 14 &&
                                    transaction.files &&
                                    transaction.files.length > 0)
                            }
                            children={
                                <IconButton
                                    className={`${classes.iconButton} ${classes.mobileOnly}`}
                                    onClick={e => {
                                        this.handleOpenPopOverMore(
                                            e,
                                            transaction,
                                        )
                                    }}
                                >
                                    <MoreHorizIcon style={{ color: `black` }} />
                                </IconButton>
                            }
                        />
                    </div>
                    <div
                        className={[
                            classes.adaptableSection,
                            classes.bigScreenOnly,
                        ].join(' ')}
                    >
                        <Typography
                            className={[
                                classes.amount,
                                realTransaction > 0
                                    ? classes.green
                                    : classes.red,
                            ].join(' ')}
                        >
                            {formatter.format(realTransaction)}
                        </Typography>
                        <div className={classes.filesIconContainer}>
                            {transaction.files && transaction.files.length > 0 && (
                                <ButtonMui
                                    onClick={() => {
                                        this.handleClosePopup()
                                        this.handleOpenModalMyDocument(
                                            transaction,
                                        )
                                    }}
                                    classes={{
                                        root: classes.filesIcon,
                                        startIcon: classes.startIconOverride,
                                    }}
                                    startIcon={<AttachFile />}
                                />
                            )}
                        </div>
                        <BoatOnBlock
                            blockIds={[11, 13, 14, 15]}
                            addedCondition={id =>
                                id !== 14 ||
                                (id === 14 &&
                                    transaction.files &&
                                    transaction.files.length > 0)
                            }
                            onlyOneId={true}
                            children={
                                <>
                                    <div className={classes.dividerH} />
                                    <IconButton
                                        className={`${classes.iconButton} ${classes.bigScreenOnly}`}
                                        onClick={e => {
                                            this.handleOpenPopOverMore(
                                                e,
                                                transaction,
                                            )
                                        }}
                                    >
                                        <MoreHorizIcon
                                            style={{ color: `black` }}
                                        />
                                    </IconButton>
                                </>
                            }
                        />
                    </div>
                </div>
            </div>
        )
    }

    render() {
        const { classes, filter } = this.props
        let { spendings, documents } = this.props
        const { openModal } = this.state

        if (!documents) {
            documents = []
        }

        return (
            <div className={classes.root}>
                <div className={classes.firstLine}>
                    <Typography className={classes.title}>
                        {this.displayText('movement')}
                    </Typography>
                </div>
                <div className={classes.infos}>
                    {spendings
                        .filter(spending => {
                            if (
                                filter &&
                                filter.search &&
                                !RegExp(filter.search.toLowerCase()).test(
                                    spending.Title.toLowerCase(),
                                )
                            ) {
                                return false
                            }
                            if (
                                filter &&
                                filter.startDate &&
                                new Date(filter.startDate).getTime() >
                                    new Date(spending.Date).getTime()
                            )
                                return false
                            if (
                                filter &&
                                filter.endDate &&
                                new Date(filter.endDate).getTime() <
                                    new Date(spending.Date).getTime()
                            )
                                return false
                            return true
                        })
                        .map(this.renderSpendings)}
                </div>
                <BoatOnModal
                    openCondition={openModal}
                    handleClose={this.handleCloseModal}
                    modalCores={{
                        showDocs: this.renderDocuments(),
                    }}
                    titles={{ showDocs: 'Mes documents' }}
                />
                {this.state.renderPopup && this._renderPopup()}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        boats: state.fetch.boats,
        documents: state.bob.documents,
        bob: state.bob,
        eventTypes: typesActions.getEventTypeTransaction(
            state.types.eventTypes,
        ),
    }
}

export default connect(mapStateToProps)(withStyles(styles)(MovementTab))
