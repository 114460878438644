import { withStyles } from '@material-ui/styles'
import React from 'react'
import BoatOnComponent from '../../../common/BoatOnComponent'
import styles from './Styles/EditUserModalCss'
import dictionary from './Dictionary/EditUserModalDictionary'
import {
    FormControlLabel,
    Paper,
    Radio,
    RadioGroup,
    Tooltip,
    Typography,
    Table,
    TableHead,
    TableCell,
    TableRow,
} from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import { Close } from '@material-ui/icons'
import { MenuItem, TextField } from '@material-ui/core'
import {
    Info as InfoIcon,
    Done as DoneIcon,
    Add as AddIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
} from '@material-ui/icons'
import PhoneField from '../../../common/PhoneField'
import { Button } from '../../../common'
import { groupActions, typesActions, userActions } from '../../../../actions'
import { connect } from 'react-redux'
import AddressSearch from '../../../common/UsefullComponents/AddressSearch'
import InputLabel from '../../../common/UsefullComponents/InputLabel'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp'
import BoatOnNumberField from '../../../common/UsefullComponents/BoatOnNumberField'
import BoatOnAppBar from '../../../common/BoatOnAppBar'
import BoatOnDateSelector from '../../../common/UsefullComponents/BoatOnDateSelector'
import BoatOnConfirmationModal from '../../../common/BoatOnConfirmationModal'
import BoatOnModal from '../../../common/BoatOnModal'
import DocumentModal from '../MyDocumentPage/DocumentModal'
import { trainingActions } from '../../../../actions/bob/trainings.actions'
import SearchBar from '../../../common/UsefullComponents/SearchBar'
import { displayText } from '../../../../utils/displayText'

const ToolTip2 = React.forwardRef(function ToolTipLink(props, ref) {
    const HtmlTooltip = withStyles(theme => ({
        tooltip: {
            backgroundColor: '#ffffff',
            color: 'black',
            maxWidth: 330,
            marginTop: -8,
            border: '1px solid #dadde9',
            fontSize: '12px',
            '& span': {
                '&:hover': {
                    cursor: `pointer`,
                },
                fontWeight: `bold`,
                textDecoration: `underline`,
            },
        },
    }))(Tooltip)

    return (
        <HtmlTooltip interactive ref={ref} title={<p>{props.title}</p>}>
            <InfoIcon />
        </HtmlTooltip>
    )
})

class EditUserModal extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
        this.state = {
            ...this.props.user,
            openPerms: false,
            hasCompany: this.props.user?.hasCompany || false,
            boatsGroup: this.props.user?.boatsGroup || [],
            permissions: JSON.parse(JSON.stringify(this.props.user.rights)),
            rightChange: false,
            roleName: '',
            roleNameError: false,
            save: false,
            tabIndex: 0,
            contractType: null,
            additionalUserInfo: JSON.parse(
                JSON.stringify(this.props.additionalUserInfo),
            ),
            selectedModal: false,
            companyNameError: false,
        }
        this.myRef = React.createRef()
        this.changeCompanyPhone = this.changeCompanyPhone.bind(this)
        this.changeCompanyAddress = this.changeCompanyAddress.bind(this)
        this.changeCompanySiret = this.changeCompanySiret.bind(this)
        this.changeCompanyName = this.changeCompanyName.bind(this)
        this.changeRoleName = this.changeRoleName.bind(this)
        this.changeFleet = this.changeFleet.bind(this)
        this.setHasCompany = this.setHasCompany.bind(this)
        this.changeRole = this.changeRole.bind(this)
        this.openPerms = this.openPerms.bind(this)
        this.save = this.save.bind(this)
        this.saveAdditionalInfos = this.saveAdditionalInfos.bind(this)
        this.changePerms = this.changePerms.bind(this)
        this.isModified = this.isModified.bind(this)
        this.isAdditionalModified = this.isAdditionalModified.bind(this)
        this.changeTab = this.changeTab.bind(this)
        this.changeBirthDate = this.changeBirthDate.bind(this)
        this.changeContract = this.changeContract.bind(this)
        this.onChangeAdditionalInfo = this.onChangeAdditionalInfo.bind(this)
        this.onChangeAdditionalDateInfo = this.onChangeAdditionalDateInfo.bind(
            this,
        )
        this.openModal = this.openModal.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.confirmDeleteTraining = this.confirmDeleteTraining.bind(this)
    }

    componentDidMount() {
        const { dispatch, user, groupId } = this.props

        if (this.state.boats) {
            this.setState({ boatsGroup: this.props.user.boatsGroup })
        }

        if (user?.id && user?.company?.id) {
            dispatch(
                groupActions.getAdditionalUserInfo(user.id, user.company.id),
            )
            dispatch(typesActions.requestContractTypes())
        }

        if (user?.userSubscribe !== null)
            dispatch(
                trainingActions.getAllTrainingByUserSubscribe(
                    groupId,
                    user.userSubscribe?.id,
                ),
            )
        else dispatch(trainingActions.getAllTrainingByUser(groupId, user?.id))
    }

    componentDidUpdate(prevProps) {
        const {
            dispatch,
            additionalUserInfo,
            user,
            loadingTraining,
            connectedUser
        } = this.props

        if(user.id === connectedUser.id){
            if(connectedUser.firstName != user.firstName){
                this.setState({firstName: connectedUser.firstName})
            }
            if(connectedUser.lastName != user.lastName){
                this.setState({lastName: connectedUser.lastName})
            }
            if(connectedUser.email != user.email){
                this.setState({email: connectedUser.email})
            }
            const normalizedDate1 = new Date(connectedUser.birthday);
            const normalizedDate2 = new Date(user.birthday);
            if(normalizedDate1.toISOString().split('T')[0] !== normalizedDate2.toISOString().split('T')[0]){
                this.setState({birthday: connectedUser.birthday})
            }
            if(connectedUser.contactDetails?.length > 0 && user.contactDetails?.length > 0 &&
                connectedUser.contactDetails[0].contact !== user.contactDetails[0].contact){
                this.setState({contactDetails: connectedUser.contactDetails})
            }
        }

        if (!prevProps.additionalUserInfo && additionalUserInfo)
            this.setState({ additionalUserInfo })

        if (!prevProps.user?.company?.id && user?.id && user?.company?.id) {
            dispatch(typesActions.requestContractTypes())
        }

        if (loadingTraining === 0 && prevProps.loadingTraining > 0) {
            this.closeModal()
        }
    }

    async save() {
        const {connectedUser, user} = this.props

        if(connectedUser?.id === user?.id &&
            (connectedUser.firstName !== this.state.firstName ||
            connectedUser.lastName !== this.state.lastName ||
            connectedUser.email !== this.state.email ||
            connectedUser.birthday !== this.state.birthday ||
            (connectedUser.contactDetails?.length > 0 && this.state.contactDetails?.length > 0 &&
                connectedUser.contactDetails[0].contact !== this.state.contactDetails[0].contact))
        ){
            await this.props.dispatch(
                userActions.updateUser({
                    ...connectedUser,
                    firstName: this.state.firstName,
                    lastName: this.state.lastName,
                    email: this.state.email,
                    birthday: this.state.birthday,
                    contactDetails: this.state.contactDetails
                })
            )
        }
        const role = JSON.parse(JSON.stringify(this.state.roles))
        if (
            this.state.roles.id < 7 &&
            this.state.rightChange &&
            this.state.roleName === ''
        ) {
            this.setState({ roleNameError: true })
            return
        } else {
            this.setState({ roleNameError: false })
        }
        if (this.state.roles.id < 7 && !this.state.roles.userRoleId) {
            role.userRoleId = this.state.roles.id
        }
        if (
            this.state.roles.id < 7 &&
            this.state.roles.userRoleId &&
            this.state.rightChange
        ) {
            role.id = null
            role.userRole = this.state.roleName
            role.userGroupId = this.props.groups.id
        }
        this.setState({ save: true })
        const roles = this.props.roles.filter(
            role => role.id === this.state.roles.id,
        )

        await this.props.dispatch(
            groupActions.editUserToGroup(
                this.state.userRGUId,
                this.state.company ? this.state.company.companyName : '',
                this.state.company ? this.state.company.siret : '',
                this.state.company ? this.state.company.address : null,
                this.state.company
                    ? this.state.company.contactDetails?.length > 0
                        ? this.state.company.contactDetails[0]?.contact
                        : ''
                    : '',
                this.state.id || null,
                (this.state.roles?.id && this.state.roles?.id < 7) ||
                    roles.length === 0
                    ? role
                    : roles[0],
                this.state.company ? this.state.company.id || null : null,
                this.state.company
                    ? this.state.company.contactDetails?.length > 0
                        ? this.state.company.contactDetails[0]?.id || null
                        : null
                    : null,
                this.state.userGroupId,
                this.state.boatsGroup,
                this.state.rightChange ? this.state.permissions : null,
                this.state.hasCompany,
                true,
            ),
        )
        if (this.state.save) {
            this.setState({ save: false })
        }
    }

    saveAdditionalInfos() {
        const { user } = this.props
        const { additionalUserInfo } = this.state

        let updatedAdditionalUserInfo = {
            userId: additionalUserInfo?.userId || user.id,
            companyId: additionalUserInfo?.companyId || user.company?.id,
            contractType: additionalUserInfo.contractType,
            registrationNumber: additionalUserInfo?.registrationNumber,
            shoeSize: additionalUserInfo?.shoeSize && additionalUserInfo?.shoeSize !== '' ? String(additionalUserInfo?.shoeSize) : null,
            topGarmentSize: additionalUserInfo?.topGarmentSize,
            downGarmentSize: additionalUserInfo?.downGarmentSize,
            gloveSize: additionalUserInfo?.gloveSize,
            delimitedDate: {
                startDate: additionalUserInfo?.delimitedDate?.startDate,
                endDate: additionalUserInfo?.delimitedDate?.endDate,
            },
        }

        if (additionalUserInfo?.id) {
            updatedAdditionalUserInfo.id = additionalUserInfo.id
        }

        this.props.dispatch(
            groupActions.editAdditionalUserInfo(updatedAdditionalUserInfo),
        )
    }

    isModified() {
        const {connectedUser, user} = this.props
        console.log(connectedUser)
        console.log(this.state)
        if(connectedUser?.id === user?.id &&
            (connectedUser.firstName !== this.state.firstName ||
            connectedUser.lastName !== this.state.lastName ||
            connectedUser.email !== this.state.email ||
            new Date(connectedUser.birthday).getUTCDate() !== new Date(this.state.birthday).getUTCDate() ||
            new Date(connectedUser.birthday).getUTCMonth() !== new Date(this.state.birthday).getUTCMonth() ||
            new Date(connectedUser.birthday).getFullYear() !== new Date(this.state.birthday).getFullYear() ||
            (connectedUser.contactDetails?.length > 0 && this.state.contactDetails?.length > 0 &&
                JSON.stringify(connectedUser.contactDetails[0].contact) !== JSON.stringify(this.state.contactDetails[0].contact)))
        ){
            return true
        }
        for (let linkRGU of this.props.groups.linkRGU) {
            if (linkRGU.id === this.state.userRGUId) {
                for (var i = 0; i < this.state.permissions.length; i++) {
                    const role = this.props.roles.filter(
                        role => role.id === this.state.roles.id,
                    )
                    if (
                        JSON.stringify(this.state.permissions[i].right) !==
                        JSON.stringify(linkRGU.rights[i].right)
                    ) {
                        console.log(JSON.stringify(this.state.permissions[i].right))
                        console.log(linkRGU.rights[i].right)
                        if (this.state.roles.id < 7) {
                            this.state.roles.userRoleId = this.state.roles.id
                        }
                        console.log('1')
                        return true
                    }
                }
                if (this.state.rightChange) {
                    this.setState({
                        rightChange: false,
                    })
                }
                if (
                    linkRGU.user?.company && this.state.company
                        ? this.state.company.companyName !==
                              linkRGU.user.company.companyName ||
                          this.state.company?.contactDetails?.[0]?.contact !==
                              linkRGU.user.company?.contactDetails?.[0]
                                  ?.contact ||
                          this.state.company.siret !==
                              linkRGU.user.company.siret ||
                          this.state.company?.address?.fullAddress !==
                              linkRGU.user.company?.address?.fullAddress ||
                          (this.state.company?.address?.fullText
                              ? this.state.company?.address?.fullText !==
                                linkRGU.user.company?.address?.fullAddress
                              : false) ||
                          (linkRGU.user.company.contactDetails?.length > 0 &&
                          linkRGU.user.company.contactDetails.filter(
                              contactDetail =>
                                  contactDetail.contactType?.id === 1,
                          ).length > 0
                              ? linkRGU.user.company.contactDetails?.filter(
                                    contactDetail =>
                                        contactDetail.contactType?.id === 1,
                                )[0]?.contact !==
                                this.state.company.contactDetails?.filter(
                                    contactDetail =>
                                        contactDetail.contactType?.id === 1,
                                )[0]?.contact
                              : linkRGU.user.company.contactDetails?.length ===
                                    0 &&
                                this.state.company.contactDetails?.length > 0)
                        : false
                ) {
                    console.log('2')
                    return true
                }
                if (linkRGU.userRole.id !== this.state.roles.id) {
                    console.log('3')
                    return true
                }
                if (linkRGU.user?.company === null && this.state.company) {
                    console.log('4')
                    return true
                }
                if (linkRGU.boats.length !== this.state.boatsGroup.length) {
                    console.log('5')
                    return true
                } else {
                    for (var i = 0; i < linkRGU.boats.length; i++) {
                        var boatIsSame = false
                        for (var j = 0; j < this.state.boatsGroup.length; j++) {
                            if (
                                linkRGU.boats[i].id ===
                                this.state.boatsGroup[j].id
                            ) {
                                boatIsSame = true
                            }
                        }
                        if (boatIsSame === false) {
                            console.log('6')
                            return true
                        }
                    }
                }
                if (linkRGU.hasCompany !== this.state.hasCompany) {
                    console.log('7')
                    return true
                }
            }
        }
        return false
    }

    isAdditionalModified() {
        const infoState = this.state.additionalUserInfo
        const infoProps = this.props.additionalUserInfo

        return infoState?.registrationNumber !== infoProps?.registrationNumber ||
            String(infoState?.shoeSize) !== String(infoProps?.shoeSize) ||
            infoState?.topGarmentSize !== infoProps?.topGarmentSize ||
            infoState?.downGarmentSize !== infoProps?.downGarmentSize ||
            infoState?.gloveSize !== infoProps?.gloveSize ||
            infoState?.delimitedDate?.startDate !== infoProps?.delimitedDate?.startDate ||
            infoState?.delimitedDate?.endDate !== infoProps?.delimitedDate?.endDate ||
            infoState?.contractType?.id !== infoProps?.contractType?.id;
    }

    setHasCompany() {
        this.setState({
            hasCompany: !this.state.hasCompany,
        })
    }

    changeCompanyPhone(c) {
        this.setState({
            company: {
                ...this.state.company,
                contactDetails: [{ contact: c }],
            },
        })
    }

    changeCompanyAddress(address) {
        this.setState(prevState => ({
            company: { ...prevState.company, address: address },
        }))
    }

    changeCompanySiret({ target: { value } }) {
        this.setState({ company: { ...this.state.company, siret: value } })
    }

    changeCompanyName(value) {
        if (value?.siret) {
            this.setState({
                company: {
                    ...this.state.company,
                    siret: value.siret,
                    companyName: value?.companyName,
                    address: {
                        fullAddress: value?.addressCompany,
                        latitude: -1,
                        longitude: -1,
                    },
                },
            })
            return
        }

        this.setState({
            company: { ...this.state.company, companyName: value?.lastValue },
        })
    }

    changeRoleName({ target: { value } }) {
        this.setState({
            roleName: value,
        })
    }

    changeBirthDate(birthday) {
        this.setState({ birthday })
    }

    changeFleet(boats) {
        if (boats) {
            this.setState({ boatsGroup: boats })
        }
    }

    changeRole({ target: { value } }) {
        this.setState({
            permissions: this.props.roles.filter(role => role.id === value)[0]
                .rights,
            roles: { id: value },
            rightChange: false,
        })
    }

    openModal(selectedModal) {
        this.setState({ selectedModal })
    }

    closeModal() {
        this.setState({ selectedModal: false })
    }

    changePerms(ind, i) {
        const { permissions } = this.state
        let perms = [...permissions]
        perms[ind].right[i] = !perms[ind].right[i]
        for (var j = 0; j < permissions.length; j++) {
            if (
                (permissions[j].right[0] === true ||
                    permissions[j].right[1] === true ||
                    permissions[j].right[2] === true) &&
                permissions[j].right[3] === false
            ) {
                if (ind === j && (i === 0 || i === 1 || i === 2)) {
                    perms[j].right[3] = true
                } else {
                    perms[j].right = [false, false, false, false]
                }
            }
        }
        this.setState({
            permissions: perms,
        })
        if (!this.state.rightChange) {
            this.setState({
                rightChange: true,
            })
        }
    }

    openPerms() {
        this.setState({ openPerms: !this.state.openPerms }, () => {
            if (this.container)
                this.container.scrollIntoView({ inline: 'end', block: 'end' })
        })
    }

    _renderPermissions() {
        const { classes } = this.props
        if (!this.state.openPerms) return null

        return (
            <>
                <div className={classes.subTwoField}>
                    <Typography className={classes.titlePerms}>
                        {this.displayText(`perso`)}
                    </Typography>
                </div>
                {this.state.rightChange && this.state.roles.id < 7 && (
                    <div className={classes.subTwoField}>
                        <TextField
                            label={this.displayText(`roleName`)}
                            onChange={this.changeRoleName}
                            className={classes.field}
                            value={this.state.roleName}
                            variant="outlined"
                            type="text"
                            error={this.state.roleNameError}
                            helperText={this.displayText(`roleDescription`)}
                        />
                    </div>
                )}
                <table className={classes.table} id="table">
                    <tbody>
                        <tr className={classes.columnsTitles}>
                            <th></th>
                            <th> {this.displayText(`add`)} </th>
                            <th> {this.displayText(`modif`)} </th>
                            <th> {this.displayText(`supp`)} </th>
                            <th> {this.displayText(`read`)} </th>
                        </tr>
                        {this.state.permissions.map((e, ind) => (
                            <tr key={ind} className={classes.rows}>
                                <td> {this.displayText(`acc` + (ind + 1))} </td>
                                {e.right.map((right, i) => (
                                    <td key={i} className={classes.row}>
                                        {(ind >= 0 && ind <= 4 && i < 3) ||
                                        (i !== 1 && i !== 3 && ind === 12) ||
                                        (i === 2 && ind === 13) ? null : (
                                            <div
                                                className={`${classes.check} ${
                                                    right
                                                        ? classes.activeCheck
                                                        : classes.notActiveCheck
                                                }`}
                                                onClick={() => {
                                                    this.changePerms(ind, i)
                                                }}
                                            >
                                                {right && (
                                                    <DoneIcon
                                                        style={{
                                                            color: `#303F9F`,
                                                            width: 14,
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        )}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </>
        )
    }

    _renderHeader() {
        const { classes, handleClose } = this.props

        return (
            <div>
                <div className={classes.header}>
                    <div className={classes.headerLeft}>
                        <div className={classes.profilePic}>
                            {this.state.firstName
                                ? this.state.firstName.substring(0, 1)
                                : ''}
                            {this.state.lastName
                                ? this.state.lastName.substring(0, 1)
                                : ''}
                        </div>
                        <div className={classes.miniDescription}>
                            <Typography variant="body1">
                                {this.state.firstName && this.state.lastName
                                    ? this.state.firstName +
                                      ' ' +
                                      this.state.lastName
                                    : ''}
                            </Typography>
                            <Typography
                                variant="body1"
                                className={classes.miniDescriptionRole}
                            >
                                {this.state.roles.userRole
                                    ? this.state.roles.userRole
                                    : this.displayTextApi(
                                          this.state.roles.translation,
                                      )}
                            </Typography>
                        </div>
                    </div>
                    <Typography
                        className={`${classes.status} ${
                            this.state.userSubscribe !== null
                                ? classes.waiting
                                : !this.state.activeAccount
                                ? classes.inactivity
                                : classes.activity
                        }`}
                    >
                        {this.state.userSubscribe !== null
                            ? this.displayText(`attente`)
                            : !this.state.activeAccount
                            ? this.displayText(`suspendu`)
                            : this.displayText(`actif`)}
                    </Typography>
                    <IconButton
                        size={`small`}
                        id={`closeDialog`}
                        onClick={handleClose}
                        style={{
                            position: `absolute`,
                            right: `10px`,
                            top: `10px`,
                            color: `#C4C4C4`,
                        }}
                    >
                        <Close />
                    </IconButton>
                </div>
            </div>
        )
    }

    _isUserEditingOwnInforation() {
        const { connectedUser, user } = this.props

        return user.email === connectedUser.email
    }

    _renderEssentialInformations() {
        const { classes, user, connectedUser, groups } = this.props
        const { hasCompany } = this.state
        const address =
            {
                ...this.state.company?.address,
                fullText: this.state.company?.address?.fullAddress,
            } || {}
        const isUserEditingCompanyOwner = true
        const isUserEdtitingOwnInformations = this._isUserEditingOwnInforation()
        console.log(groups)
        console.log(user)
        console.log(connectedUser)

        return (
            <>
                {this.state.userSubscribe === null && (
                    <>
                        <Typography className={classes.subtitle}>
                            {this.displayText(`newU`)}
                        </Typography>
                        <RadioGroup
                            className={classes.radioButton}
                            aria-label="gender"
                            name="gender1"
                            value={hasCompany}
                            onChange={this.setHasCompany}
                        >
                            <FormControlLabel
                                checked={hasCompany}
                                value={true}
                                control={<Radio style={{ color: `#303F9F` }} />}
                                label={this.displayText(`oui`)}
                            />
                            <FormControlLabel
                                checked={!hasCompany}
                                value={false}
                                control={<Radio style={{ color: `#303F9F` }} />}
                                label={this.displayText(`non`)}
                            />
                        </RadioGroup>
                        {hasCompany && (
                            <>
                                <div className={classes.twoField}>
                                    <div className={classes.subTwoField}>
                                        <SearchBar
                                            onValueChange={
                                                this.changeCompanyName
                                            }
                                            onSearch={this.changeCompanyName}
                                            addressValue={
                                                this.state.company
                                                    ?.companyName || null
                                            }
                                            searchType="company"
                                            label={this.displayText(`societe`)}
                                            autoCompletion={null}
                                            margin="dense"
                                            error={this.state.companyNameError}
                                            errorText={this.displayText('errorCompanyName')}
                                        />
                                    </div>
                                    <div className={classes.subTwoField}>
                                        <BoatOnNumberField
                                            label={this.displayText(`SIRET`)}
                                            onChange={this.changeCompanySiret}
                                            className={classes.field}
                                            value={
                                                this.state.company?.siret || ''
                                            }
                                            variant="outlined"
                                            disabled={
                                                !isUserEditingCompanyOwner
                                            }
                                            onKeyDown={e => {
                                                if (/\.|,/.test(e.key))
                                                    e.preventDefault()
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className={classes.twoField}>
                                    <div className={classes.subTwoFieldPhone}>
                                        <AddressSearch
                                            label={this.displayText(`adresse`)}
                                            setAddress={
                                                this.changeCompanyAddress
                                            }
                                            fullAddressInfo={address?.fullText}
                                            margin="normal"
                                            style={{
                                                margin: `12px 0`,
                                                width: `100%`,
                                                color: `black`,
                                            }}
                                            disabled={
                                                !isUserEditingCompanyOwner
                                            }
                                        ></AddressSearch>
                                    </div>
                                    <div className={classes.subTwoFieldPhone}>
                                        <PhoneField
                                            onChange={this.changeCompanyPhone}
                                            className={classes.fieldPhone}
                                            label={this.displayText(`phone`)}
                                            value={
                                                this.state.company?.contactDetails?.filter(
                                                    contactDetail =>
                                                        contactDetail
                                                            .contactType?.id ===
                                                        1,
                                                )[0]?.contact || ''
                                            }
                                            disabled={
                                                !isUserEditingCompanyOwner
                                            }
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                    </>
                )}

                <Typography className={classes.subtitle}>
                    {this.displayText(`infP`)}
                </Typography>

                <div className={classes.twoField}>
                    <TextField
                        label={this.displayText(`nom`)}
                        className={classes.field}
                        value={this.state.lastName}
                        onChange={e => this.setState({lastName: e.target.value})}
                        variant="outlined"
                        type="text"
                        disabled={!isUserEdtitingOwnInformations}
                    />
                    <TextField
                        label={this.displayText(`prenom`)}
                        className={classes.field}
                        value={this.state.firstName}
                        onChange={e => this.setState({firstName: e.target.value})}
                        variant="outlined"
                        type="text"
                        disabled={!isUserEdtitingOwnInformations}
                    />
                </div>
                <div className={classes.twoField}>
                    <TextField
                        label={this.displayText(`email`)}
                        className={classes.field}
                        value={
                            this.state.email || this.state.userSubscribe?.mail
                        }
                        onChange={e => this.state.email ? this.setState({email: e.target.value}) : this.setState({userSubscribe: {mail: e.target.value}})}
                        variant="outlined"
                        type="text"
                        disabled={!isUserEdtitingOwnInformations}
                    />
                    <PhoneField
                        className={classes.fieldPhone}
                        onChange={e => this.setState({contactDetails: [
                            { ...this.state.contactDetails[0],
                                contact: e
                            }]
                        })}
                        label={this.displayText(`phone`)}
                        value={
                            this.state.contactDetails?.filter(
                                contactDetail =>
                                    contactDetail.contactType?.id === 1,
                            ).length > 0
                                ? this.state.contactDetails?.filter(
                                      contactDetail =>
                                          contactDetail.contactType?.id === 1,
                                  )[0].contact
                                : ''
                        }
                        disabled={!isUserEdtitingOwnInformations}
                    />
                </div>
                <div className={classes.singleField}>
                    <BoatOnDateSelector
                        disabled={!isUserEdtitingOwnInformations}
                        label={this.displayText('birthday')}
                        onChange={e => this.setState({birthday: e})}
                        className={classes.field}
                        value={
                            this.state.birthday
                                ? new Date(this.state.birthday)
                                : null
                        }
                    />
                </div>
                {groups?.user?.id !== user.id &&
                    this._renderBlocLink()}
            </>
        )
    }

    onChangeAdditionalInfo(value, property) {
        const { additionalUserInfo } = this.state
        if(property === 'topGarmentSize' || property === 'downGarmentSize' || property === 'gloveSize'){
            value = value.slice(0, 4)
        }
        this.setState({
            additionalUserInfo: {
                ...additionalUserInfo,
                [property]: value,
            },
        })
    }

    onChangeAdditionalDateInfo(value, property) {
        let { additionalUserInfo } = this.state

        if (additionalUserInfo?.delimitedDate) {
            additionalUserInfo.delimitedDate = {
                ...additionalUserInfo.delimitedDate,
                [property]: value,
            }
        } else {
            additionalUserInfo = {
                ...additionalUserInfo,
                delimitedDate: {
                    startDate: null,
                    endDate: null,
                    [property]: value,
                },
            }
        }

        this.setState({ additionalUserInfo })
    }

    changeContract(e) {
        const { contractTypes } = this.props

        const contractType = contractTypes.find(
            contract => contract.id === e.target.value,
        )

        this.setState({
            additionalUserInfo: {
                ...this.state.additionalUserInfo,
                contractType: {
                    id: e.target.value,
                },
            },
        })
    }

    _renderAdditionalInfoBlock() {
        const { classes, contractTypes } = this.props
        const { additionalUserInfo } = this.state

        const startDate = additionalUserInfo?.delimitedDate?.startDate
            ? new Date(additionalUserInfo.delimitedDate.startDate)
            : null

        const endDate = additionalUserInfo?.delimitedDate?.endDate
            ? new Date(additionalUserInfo.delimitedDate.endDate)
            : null

        return (
            <>
                <Typography className={classes.subtitle}>
                    {this.displayText(`additionalInformations`)}
                </Typography>
                <div className={classes.flexRowContainer}>
                    <div className={classes.additionalField}>
                        <BoatOnDateSelector
                            label={this.displayText('enterDateInCompany')}
                            value={startDate}
                            onChange={value =>
                                this.onChangeAdditionalDateInfo(
                                    value,
                                    'startDate',
                                )
                            }
                        />
                    </div>
                    <div className={classes.additionalField}>
                        <BoatOnDateSelector
                            label={this.displayText('leavingDateInCompany')}
                            value={endDate}
                            onChange={value =>
                                this.onChangeAdditionalDateInfo(
                                    value,
                                    'endDate',
                                )
                            }
                        />
                    </div>
                    <TextField
                        select
                        variant="outlined"
                        label={this.displayText('contractType')}
                        className={classes.additionalField}
                        value={additionalUserInfo?.contractType?.id}
                        onChange={this.changeContract}
                        classes={{
                            root: classes.contractRoot,
                        }}
                    >
                        {(contractTypes || []).map(contract => (
                            <MenuItem key={contract.id} value={contract.id}>
                                {this.displayTextApi(contract.translation)}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        label={this.displayText(`idNumber`)}
                        className={classes.additionalField}
                        value={additionalUserInfo?.registrationNumber}
                        onChange={e =>
                            this.onChangeAdditionalInfo(
                                e.target.value,
                                'registrationNumber',
                            )
                        }
                        variant="outlined"
                        type="text"
                    />
                    <TextField
                        label={this.displayText(`shoesSize`)}
                        className={classes.additionalField}
                        value={additionalUserInfo?.shoeSize}
                        onChange={e =>
                            this.onChangeAdditionalInfo(
                                e.target.value,
                                'shoeSize',
                            )
                        }
                        variant="outlined"
                        type="number"
                    />
                    <TextField
                        label={this.displayText(`topClothesSize`)}
                        className={classes.additionalField}
                        value={additionalUserInfo?.topGarmentSize}
                        onChange={e =>
                            this.onChangeAdditionalInfo(
                                e.target.value,
                                'topGarmentSize',
                            )
                        }
                        variant="outlined"
                        type="text"
                    />
                    <TextField
                        label={this.displayText(`bottomClothesSize`)}
                        className={classes.additionalField}
                        value={additionalUserInfo?.downGarmentSize}
                        onChange={e =>
                            this.onChangeAdditionalInfo(
                                e.target.value,
                                'downGarmentSize',
                            )
                        }
                        variant="outlined"
                        type="text"
                    />
                    <TextField
                        label={this.displayText(`glovesSize`)}
                        className={classes.additionalField}
                        value={additionalUserInfo?.gloveSize}
                        onChange={e =>
                            this.onChangeAdditionalInfo(
                                e.target.value,
                                'gloveSize',
                            )
                        }
                        variant="outlined"
                        type="text"
                    />
                </div>
            </>
        )
    }

    _editTraining(training) {
        this.setState({
            selectedTraining: training,
            selectedModal: 'editFormation',
        })
    }

    _deleteTraining(training) {
        this.setState({
            selectedTraining: training,
            selectedModal: 'deleteConfirm',
        })
    }

    confirmDeleteTraining() {
        const { groupId } = this.props
        const { selectedTraining } = this.state

        this.props.dispatch(
            trainingActions.deleteTraining(selectedTraining?.id, groupId),
        )

        this.setState({
            selectedTraining: null,
            selectedModal: false,
        })
    }

    _renderAdditionalInformations() {
        const { classes, user, trainings } = this.props

        return (
            <>
                {user?.company?.id && this._renderAdditionalInfoBlock()}
                <Typography className={classes.subtitle}>
                    {this.displayText(`formations`)}
                </Typography>
                <Table className={classes.trainingTable} size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                {this.displayText('trainingName')}
                            </TableCell>
                            <TableCell>
                                {this.displayText('acquisitionDate')}
                            </TableCell>
                            <TableCell>
                                {this.displayText('expiryDate')}
                            </TableCell>
                            <TableCell align="right" />
                        </TableRow>
                    </TableHead>
                    {trainings.map((training, index) => (
                        <TableRow key={`training-${index}`}>
                            <TableCell>
                                {training?.trainingType
                                    ? this.displayTextApi(
                                          training.trainingType.translation,
                                      )
                                    : training?.userTraining
                                    ? training.userTraining?.name
                                    : ''}
                            </TableCell>
                            <TableCell>
                                {new Date(
                                    training.delimitedDate.startDate,
                                ).toLocaleDateString('fr-FR')}
                            </TableCell>
                            <TableCell>
                                {training.delimitedDate?.endDate &&
                                    new Date(
                                        training.delimitedDate.endDate,
                                    ).toLocaleDateString('fr-FR')}
                            </TableCell>
                            <TableCell className={classes.actionCell}>
                                <EditIcon
                                    onClick={() => this._editTraining(training)}
                                />
                                <DeleteIcon
                                    onClick={() =>
                                        this._deleteTraining(training)
                                    }
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </Table>
                <Button
                    label={
                        <div className={classes.formationButtonLabel}>
                            <AddIcon />
                            {this.displayText('addFormation')}
                        </div>
                    }
                    classNameOverride={classes.formationButton}
                    onClick={() => this.openModal('addFormation')}
                    type="text"
                />
            </>
        )
    }

    changeTab(tabIndex) {
        this.setState({ tabIndex })
    }

    _renderCore() {
        const { classes } = this.props
        const { tabIndex } = this.state

        return (
            <div className={classes.coreContainer}>
                <BoatOnAppBar
                    tabIndex={tabIndex}
                    displayBodyOut={this.changeTab}
                    labels={[
                        this.displayText('essentialInfos'),
                        this.displayText('additionalInfos'),
                    ]}
                />
                {tabIndex === 0 && this._renderEssentialInformations()}
                {tabIndex === 1 && this._renderAdditionalInformations()}
            </div>
        )
    }

    _renderBlocLink() {
        const { classes } = this.props
        const roles = this.props.roles?.filter(
            role => role.id < 7 || role.userGroupId === this.props.groups?.id,
        )
        return (
            <div className={classes.blocLink}>
                <InputLabel
                    boats={this.props.boats}
                    onTagsChange={this.changeFleet}
                    modalOpen={this.state.modalOpen}
                    handleOpenModal={this.handleOpenModal}
                    selected={
                        this.state.boatsGroup?.length > 0
                            ? this.state.boatsGroup
                            : []
                    }
                />
                <Typography className={classes.subtitle}>
                    {' '}
                    {this.displayText(`roleEtPerm`)}{' '}
                </Typography>
                <TextField
                    className={`${classes.field} ${classes.fieldRole}`}
                    id="boatTypeId"
                    required
                    select
                    variant="outlined"
                    value={this.state.roles.id}
                    onChange={this.changeRole}
                >
                    {[...roles].map((r, index) => (
                        <MenuItem
                            key={r.id}
                            className={classes.menuItemRole}
                            value={r.id}
                        >
                            {' '}
                            {r.userRole
                                ? r.userRole
                                : this.displayTextApi(r.translation)}
                            <ToolTip2
                                style={{ fontSize: `20px` }}
                                title={this.displayTextApi(
                                    r.translationDescription,
                                )}
                                aria-label="add"
                            />
                        </MenuItem>
                    ))}
                </TextField>
                <div style={{ display: 'flex', alignItems: 'stretch' }}>
                    <Typography
                        className={classes.openPerms}
                        onClick={this.openPerms}
                    >
                        {' '}
                        {this.displayText(`persoRole`)}
                        {'     '}
                    </Typography>
                    {!this.state.openPerms ? (
                        <KeyboardArrowDown
                            style={{ paddingTop: 5, marginLeft: 2 }}
                            onClick={this.openPerms}
                        />
                    ) : (
                        <KeyboardArrowUp
                            style={{ paddingTop: 5, marginLeft: 2 }}
                            onClick={this.openPerms}
                        />
                    )}
                </div>
                {this.isModified() && !this.state.openPerms && (
                    <div style={{ height: 80 }}></div>
                )}
                {this._renderPermissions()}
                <div style={{ height: 80 }}></div>
            </div>
        )
    }

    _renderSaveBar() {
        const { classes } = this.props
        return (
            <Paper elevation={0} className={classes.saveBar}>
                <Button
                    label={this.displayText(`save`)}
                    onClick={this.save}
                    disabled={this.state.save}
                />
            </Paper>
        )
    }

    _renderAdditionalSaveBar() {
        const { classes } = this.props
        return (
            <Paper elevation={0} className={classes.saveBar}>
                <Button
                    label={this.displayText(`save`)}
                    onClick={this.saveAdditionalInfos}
                    disabled={this.state.save}
                />
            </Paper>
        )
    }

    render() {
        const { eventTypes, user } = this.props
        const { selectedModal, selectedTraining } = this.state

        return (
            <div
                ref={element => {
                    this.container = element
                }}
            >
                {this._renderHeader()}
                {this._renderCore()}
                {this.isModified() && this._renderSaveBar()}
                {this.isAdditionalModified() && this._renderAdditionalSaveBar()}

                <BoatOnModal
                    openCondition={selectedModal}
                    handleClose={this.closeModal}
                    modalCores={{
                        addFormation: (
                            <DocumentModal
                                eventTypes={eventTypes}
                                forceEventType={eventTypes.find(
                                    type => type.id === 22,
                                )}
                                event={null}
                                training={null}
                                noCross={false}
                                initialUser={user}
                            />
                        ),
                        editFormation: (
                            <DocumentModal
                                eventTypes={eventTypes}
                                forceEventType={eventTypes.find(
                                    type => type.id === 22,
                                )}
                                event={null}
                                training={selectedTraining}
                                noCross={false}
                                initialUser={user}
                            />
                        ),
                        deleteConfirm: (
                            <BoatOnConfirmationModal
                                onClose={this.closeModal}
                                callback={this.confirmDeleteTraining}
                                message={this.displayText(
                                    'deleteConfirmMessage',
                                )}
                            />
                        ),
                    }}
                    maxWidth={{
                        addFormation: 'sm',
                        editFormation: 'sm',
                        deleteConfirm: 'sm',
                    }}
                    titles={{
                        addFormation: this.displayText('addFormation'),
                        editFormation: this.displayText('editFormation'),
                        deleteConfirm: this.displayText('deleteConfirmTitle'),
                    }}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        roles: state.group.roles,
        groups: state.group.groupsMembers,
        groupId: state.group?.currentGroupId,
        connectedUser: state.authentication.user,
        additionalUserInfo: state.group.additionalUserInfo,
        eventTypes: typesActions.getEventTypeDocument(state.types.eventTypes),
        contractTypes: state.types.contractTypes,
        loadingTraining: state.trainings.loading,
        trainings: state.trainings.trainings || [],
    }
}

export default connect(mapStateToProps)(withStyles(styles)(EditUserModal))
