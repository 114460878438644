import {
    bobEventsConstants,
    boatConstants,
    navigationApiDataConstants,
} from '../constants'
import { boatReducer } from './bob/boat.reducer'
import { LS_USER_KEY } from '../services/config.service'

const initialState = {
    boat: null,
    events: null,
    repairs: null, //to remove
    spendings: null, //to remove
    checkups: null, //to remove
    documents: null, //to remove
    activecheckups: null, //to remove
    checkupsothers: null, //to remove
    loading: 0,
    searchData: null,
    newEvent: null,
}

export default function fetchBOB(prevState = initialState, action) {
    if (action.type in boatConstants) {
        return boatReducer(prevState, action)
    }
    if (action.type in bobEventsConstants) {
        return bobEventReducer(prevState, action)
    }
    return prevState
}

const _getPartObject = (inventories, family, equipment, event, part) => {
    return part
        ? {
              ...(inventories?.equipmentFamilies[family?.id] &&
              inventories?.equipmentFamilies[family?.id]?.equipments[
                  equipment.id
              ]
                  ? inventories?.equipmentFamilies[family?.id]?.equipments[
                        equipment?.id
                    ].parts
                  : {}),
              [part.id]: {
                  partType: part.partType,
                  userPart: part.userPart,
                  event: event,
              },
          }
        : inventories.equipmentFamilies[family?.id] &&
          inventories.equipmentFamilies[family?.id].equipments[equipment?.id]
        ? inventories.equipmentFamilies[family?.id].equipments[equipment?.id]
              .parts
        : {}
}

const _getEquipmentObject = (inventories, family, equipment, event, part) => {
    const partObject = _getPartObject(
        inventories,
        family,
        equipment,
        event,
        part,
    )
    return inventories?.equipmentFamilies[family?.id]
        ? {
              ...inventories?.equipmentFamilies[family?.id]?.equipments,
              [equipment?.id]: event?.detail?.equipment
                  ? {
                        equipmentType: equipment?.equipmentType,
                        userEquipment: equipment?.userEquipment,
                        event: part ? null : event,
                        parts: partObject,
                    }
                  : inventories?.equipmentFamilies[family?.id]
                  ? {
                        ...inventories?.equipmentFamilies[family?.id]
                            ?.equipments[equipment?.id],
                        parts: partObject,
                    }
                  : {
                        parts: partObject,
                    },
          }
        : {
              [equipment?.id]: event?.detail?.equipment
                  ? {
                        equipmentType: equipment?.equipmentType,
                        userEquipment: equipment?.userEquipment,
                        event: part ? null : event,
                        parts: partObject,
                    }
                  : inventories?.equipmentFamilies[family?.id]
                  ? {
                        ...inventories?.equipmentFamilies[family?.id]
                            ?.equipments[equipment?.id],
                        parts: partObject,
                    }
                  : {
                        parts: partObject,
                    },
          }
}

const _initInventories = events => {
    const inventories = { equipmentFamilies: {} }
    const eventsToAdd = []
    events.forEach(event => {
        if (event.detail) {
            eventsToAdd.push(event)
        }
    })
    eventsToAdd
        .filter(
            event =>
                event.delimitedDate &&
                event.delimitedDate.startDate &&
                !event.detail.archived,
        )
        .forEach(event => {
            let { part, equipment } = event.detail
            if (part && !equipment) equipment = part.equipment
            const family = part
                ? part?.equipment?.equipmentFamily
                : equipment?.equipmentFamily
            const equipmentObject = _getEquipmentObject(
                inventories,
                family,
                equipment,
                event,
                part,
            )

            inventories.equipmentFamilies = {
                ...inventories?.equipmentFamilies,
                [family?.id]: {
                    ...inventories?.equipmentFamilies[family?.id],
                    type: family?.type,
                    user: family?.user,
                    equipments: equipmentObject,
                },
            }
        })
    return inventories
}

const bobEventReducer = (prevState, action) => {
    const userInfos = JSON.parse(localStorage.getItem(LS_USER_KEY))
    let events = prevState.events || []

    switch (action.type) {
        case bobEventsConstants.REQUEST_BOBEVENTS:
            return {
                ...prevState,
                events: null,
                loading: prevState.loading + 1,
            }
        case bobEventsConstants.POST_BOBEVENTS:
            return {
                ...prevState,
                loading: prevState.loading + 1,
            }
        case bobEventsConstants.PUT_BOBEVENTS:
            return {
                ...prevState,
                loading: prevState.loading + 1,
            }
        case bobEventsConstants.PUT_BOBEVENTS_SUCCESS:
            // correction en dur pour les 3 equipement type en anglais en attendant la tradcution back
            // osmoseur = 108
            // Groupe électrogène tribord = 25
            // Groupe électrogène babord = 26

            events = events.map(event => {
                const edit = action.events.find(
                    eventEdit => event?.id && eventEdit?.id === event?.id,
                )
                if (edit) return edit
                return event
            })

            if (userInfos?.id === 64) {
                action.events = action.events.map(event => {
                    if (event?.checkup?.equipment?.id === 108) {
                        event.checkup.equipment.equipmentType.equipmentTypeFr = `Watermaker`
                    } else if (event?.checkup?.equipment?.id === 25) {
                        event.checkup.equipment.equipmentType.equipmentTypeFr = `Generator starboard`
                    } else if (event?.checkup?.equipment?.id === 26) {
                        event.detail.equipment.equipmentType.equipmentTypeFr = `Generator port side`
                    } else if (event?.detail?.equipment?.id === 108) {
                        event.detail.equipment.equipmentType.equipmentTypeFr = `Watermaker`
                    } else if (event?.detail?.equipment?.id === 25) {
                        event.detail.equipment.equipmentType.equipmentTypeFr = `Generator starboard`
                    } else if (event?.detail?.equipment?.id === 26) {
                        event.detail.equipment.equipmentType.equipmentTypeFr = `Generator port side`
                    }
                    return event
                })
            }
            // fin de l'immonderie pour wistream
            return {
                ...prevState,
                events: events,
                inventories: _initInventories(
                    JSON.parse(JSON.stringify(events)),
                ),
                loading: prevState.loading - 1,
            }
        case bobEventsConstants.POST_BOBEVENTS_SUCCESS:
            // correction en dur pour les 3 equipement type en anglais en attendant la tradcution back
            // osmoseur = 108
            // Groupe électrogène tribord = 25
            // Groupe électrogène babord = 26

            action.events.forEach(newEvent => {
                const after = events.findIndex(
                    event =>
                        newEvent?.delimitedDate?.startDate &&
                        event?.delimitedDate?.startDate &&
                        new Date(event.delimitedDate.startDate).getTime() <
                            new Date(
                                newEvent.delimitedDate.startDate,
                            ).getTime(),
                )

                if (after === -1) events.push(newEvent)
                else {
                    events.splice(after, 0, newEvent)
                }
            })

            if (userInfos?.id === 64) {
                action.events = action.events.map(event => {
                    if (event?.checkup?.equipment?.id === 108) {
                        event.checkup.equipment.equipmentType.equipmentTypeFr = `Watermaker`
                    } else if (event?.checkup?.equipment?.id === 25) {
                        event.checkup.equipment.equipmentType.equipmentTypeFr = `Generator starboard`
                    } else if (event?.checkup?.equipment?.id === 26) {
                        event.detail.equipment.equipmentType.equipmentTypeFr = `Generator port side`
                    } else if (event?.detail?.equipment?.id === 108) {
                        event.detail.equipment.equipmentType.equipmentTypeFr = `Watermaker`
                    } else if (event?.detail?.equipment?.id === 25) {
                        event.detail.equipment.equipmentType.equipmentTypeFr = `Generator starboard`
                    } else if (event?.detail?.equipment?.id === 26) {
                        event.detail.equipment.equipmentType.equipmentTypeFr = `Generator port side`
                    }
                    return event
                })
            }
            // fin de l'immonderie pour wistream
            return {
                ...prevState,
                events: events,
                inventories: _initInventories(
                    JSON.parse(JSON.stringify(events)),
                ),
                loading: prevState.loading - 1,
            }
        case bobEventsConstants.DELETE_BOBEVENTS_SUCCESS:
            if (action.eventUpdatedIds) {
                action.eventUpdatedIds.forEach(eventId => {
                    const index = events.findIndex(
                        event => event.id === eventId,
                    )

                    if (index !== -1) {
                        if (action.deleteType === 'transaction')
                            events[index] = {
                                ...events[index],
                                transaction: null,
                            }
                        else if (action.deleteType === 'delimitedDate')
                            events[index] = {
                                ...events[index],
                                delimitedDate: null,
                            }
                        else if (action.deleteType === 'files')
                            events[index] = { ...events[index], files: null }
                        else if (action.deleteType === 'checkup')
                            events[index] = { ...events[index], checkup: null }
                        else if (action.deleteType === 'detail')
                            events[index] = { ...events[index], detail: null }
                    }
                })
            }
            if (action.eventDeletedIds) {
                action.eventDeletedIds.forEach(eventId => {
                    events.splice(
                        events.findIndex(event => event.id === eventId),
                        1,
                    )
                })
            }
            return {
                ...prevState,
                events: events,
                inventories: _initInventories(
                    JSON.parse(JSON.stringify(events)),
                ),
                loading: prevState.loading - 1,
            }
        case bobEventsConstants.BOBEVENTS_SUCCESS:
            // correction en dur pour les 3 equipement type en anglais en attendant la tradcution back
            // osmoseur = 108
            // Groupe électrogène tribord = 25
            // Groupe électrogène babord = 26
            if (userInfos?.id === 64) {
                action.events = action.events.map(event => {
                    if (event?.checkup?.equipment?.id === 108) {
                        event.checkup.equipment.equipmentType.equipmentTypeFr = `Watermaker`
                    } else if (event?.checkup?.equipment?.id === 25) {
                        event.checkup.equipment.equipmentType.equipmentTypeFr = `Generator starboard`
                    } else if (event?.checkup?.equipment?.id === 26) {
                        event.detail.equipment.equipmentType.equipmentTypeFr = `Generator port side`
                    } else if (event?.detail?.equipment?.id === 108) {
                        event.detail.equipment.equipmentType.equipmentTypeFr = `Watermaker`
                    } else if (event?.detail?.equipment?.id === 25) {
                        event.detail.equipment.equipmentType.equipmentTypeFr = `Generator starboard`
                    } else if (event?.detail?.equipment?.id === 26) {
                        event.detail.equipment.equipmentType.equipmentTypeFr = `Generator port side`
                    }
                    return event
                })
            }
            // fin de l'immonderie pour wistream
            return {
                ...prevState,
                events: action.events,
                inventories: _initInventories(
                    JSON.parse(JSON.stringify(action.events)),
                ),
                loading: prevState.loading - 1,
            }
        case bobEventsConstants.BOBEVENTS_FAILURE:
            return {
                ...prevState,
                events: null,
                loading: prevState.loading - 1,
            }
        case bobEventsConstants.BOBEVENTS_SETNEWEVENT:
            return {
                ...prevState,
                newEvent: action.newEvent,
            }
        case bobEventsConstants.BOBEVENTS_UNSETNEWEVENT:
            return {
                ...prevState,
                newEvent: null,
            }
        case bobEventsConstants.RESET:
            return {
                ...initialState,
                loading: prevState.loading,
            }
        case bobEventsConstants.UPDATE_USEREQUIPMENT_NAME_SUCCESS:
            events = events.map(event => {
                if (
                    event?.detail?.equipment?.userEquipment &&
                    event?.detail?.equipment?.userEquipment?.id ===
                        action.userEquipment?.id
                ) {
                    event.detail.equipment.userEquipment.name =
                        action.userEquipment.name
                } else if (
                    event?.detail?.part?.equipment?.userEquipment &&
                    event?.detail?.part?.equipment?.userEquipment?.id ===
                        action.userEquipment?.id
                )
                    event.detail.part.equipment.userEquipment.name =
                        action.userEquipment.name
                return event
            })

            if (userInfos?.id === 64) {
                action.events = action.events.map(event => {
                    if (event?.checkup?.equipment?.id === 108) {
                        event.checkup.equipment.equipmentType.equipmentTypeFr = `Watermaker`
                    } else if (event?.checkup?.equipment?.id === 25) {
                        event.checkup.equipment.equipmentType.equipmentTypeFr = `Generator starboard`
                    } else if (event?.checkup?.equipment?.id === 26) {
                        event.detail.equipment.equipmentType.equipmentTypeFr = `Generator port side`
                    } else if (event?.detail?.equipment?.id === 108) {
                        event.detail.equipment.equipmentType.equipmentTypeFr = `Watermaker`
                    } else if (event?.detail?.equipment?.id === 25) {
                        event.detail.equipment.equipmentType.equipmentTypeFr = `Generator starboard`
                    } else if (event?.detail?.equipment?.id === 26) {
                        event.detail.equipment.equipmentType.equipmentTypeFr = `Generator port side`
                    }
                    return event
                })
            }
            // fin de l'immonderie pour wistream
            return {
                ...prevState,
                events: events,
                inventories: _initInventories(
                    JSON.parse(JSON.stringify(events)),
                ),
                loading: prevState.loading - 1,
            }
        case bobEventsConstants.UPDATE_USERPART_NAME_SUCCESS:
            events = events.map(event => {
                if (
                    event?.detail?.part?.userPart &&
                    event?.detail?.part?.userPart?.id === action.userPart?.id
                ) {
                    event.detail.part.userPart = action.userPart
                }
                return event
            })

            if (userInfos?.id === 64) {
                action.events = action.events.map(event => {
                    if (event?.checkup?.equipment?.id === 108) {
                        event.checkup.equipment.equipmentType.equipmentTypeFr = `Watermaker`
                    } else if (event?.checkup?.equipment?.id === 25) {
                        event.checkup.equipment.equipmentType.equipmentTypeFr = `Generator starboard`
                    } else if (event?.checkup?.equipment?.id === 26) {
                        event.detail.equipment.equipmentType.equipmentTypeFr = `Generator port side`
                    } else if (event?.detail?.equipment?.id === 108) {
                        event.detail.equipment.equipmentType.equipmentTypeFr = `Watermaker`
                    } else if (event?.detail?.equipment?.id === 25) {
                        event.detail.equipment.equipmentType.equipmentTypeFr = `Generator starboard`
                    } else if (event?.detail?.equipment?.id === 26) {
                        event.detail.equipment.equipmentType.equipmentTypeFr = `Generator port side`
                    }
                    return event
                })
            }
            // fin de l'immonderie pour wistream
            return {
                ...prevState,
                events: events,
                inventories: _initInventories(
                    JSON.parse(JSON.stringify(events)),
                ),
                loading: prevState.loading - 1,
            }
        case bobEventsConstants.UPDATE_EQUIPMENTTYPE_NAME_SUCCESS:
            events = events.map(event => {
                const edit = action.events.find(
                    eventEdit => event?.id && eventEdit?.id === event?.id,
                )
                if (edit) return edit
                return event
            })

            if (userInfos?.id === 64) {
                action.events = action.events.map(event => {
                    if (event?.checkup?.equipment?.id === 108) {
                        event.checkup.equipment.equipmentType.equipmentTypeFr = `Watermaker`
                    } else if (event?.checkup?.equipment?.id === 25) {
                        event.checkup.equipment.equipmentType.equipmentTypeFr = `Generator starboard`
                    } else if (event?.checkup?.equipment?.id === 26) {
                        event.detail.equipment.equipmentType.equipmentTypeFr = `Generator port side`
                    } else if (event?.detail?.equipment?.id === 108) {
                        event.detail.equipment.equipmentType.equipmentTypeFr = `Watermaker`
                    } else if (event?.detail?.equipment?.id === 25) {
                        event.detail.equipment.equipmentType.equipmentTypeFr = `Generator starboard`
                    } else if (event?.detail?.equipment?.id === 26) {
                        event.detail.equipment.equipmentType.equipmentTypeFr = `Generator port side`
                    }
                    return event
                })
            }
            // fin de l'immonderie pour wistream
            return {
                ...prevState,
                events: events,
                inventories: _initInventories(
                    JSON.parse(JSON.stringify(events)),
                ),
                loading: prevState.loading - 1,
            }
        case bobEventsConstants.UPDATE_PARTTYPE_NAME_SUCCESS:
            events = events.map(event => {
                const edit = action.events.find(
                    eventEdit => event?.id && eventEdit?.id === event?.id,
                )
                if (edit) return edit
                return event
            })

            if (userInfos?.id === 64) {
                action.events = action.events.map(event => {
                    if (event?.checkup?.equipment?.id === 108) {
                        event.checkup.equipment.equipmentType.equipmentTypeFr = `Watermaker`
                    } else if (event?.checkup?.equipment?.id === 25) {
                        event.checkup.equipment.equipmentType.equipmentTypeFr = `Generator starboard`
                    } else if (event?.checkup?.equipment?.id === 26) {
                        event.detail.equipment.equipmentType.equipmentTypeFr = `Generator port side`
                    } else if (event?.detail?.equipment?.id === 108) {
                        event.detail.equipment.equipmentType.equipmentTypeFr = `Watermaker`
                    } else if (event?.detail?.equipment?.id === 25) {
                        event.detail.equipment.equipmentType.equipmentTypeFr = `Generator starboard`
                    } else if (event?.detail?.equipment?.id === 26) {
                        event.detail.equipment.equipmentType.equipmentTypeFr = `Generator port side`
                    }
                    return event
                })
            }
            // fin de l'immonderie pour wistream
            return {
                ...prevState,
                events: events,
                inventories: _initInventories(
                    JSON.parse(JSON.stringify(events)),
                ),
                loading: prevState.loading - 1,
            }
        case bobEventsConstants.BOBEVENTS_PUT_FAILURE:
            return {
                ...prevState,
                loading: prevState.loading - 1,
            }
        default:
            return prevState
    }
}
