import React from 'react'
import BoatOnComponent from '../../../../common/BoatOnComponent'

import { connect } from 'react-redux'
import { withStyles } from '@material-ui/styles'
import styles from './UserPaidVacationsTabCss'
import dictionary from './UserPaidVacationsTabDico'
import {
    Button,
    Checkbox,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@material-ui/core'
import PersonIcon from '@mui/icons-material/Person'
import EditIcon from '@mui/icons-material/Edit'
import worktimesUtils, {
    DEFAULT_NB_HOURS_PER_DAY,
} from '../../../../../utils/worktimesUtils'
import { absencesActions } from '../../../../../actions/bob/absences.actions'
import BoatOnModal from '../../../../common/BoatOnModal'
import AllowedVacationsModal from '../AllowedVacationsModal/AllowedVacationsModal'
import { fixIosHeaderBug } from '../../../../../utils/usefullFunctions'
import BoatOnBlock from '../../../../common/Blocks/BoatOnBlock'

class UserPaidVacationsTab extends BoatOnComponent {
    constructor(props) {
        super(props)

        this.state = {
            settings: props.settings,
            isFooterOpen: false,
            checkedUsers: [],
            rows: [],
            modaleSelectedUsers: [],
            isEditDaysModalOpen: false,
            valueModalAllowedDays: null,
            cellValue: null,
            timer: null,
            searchString: '',
            openedModal: null,
        }

        this.openEditDaysModal = this.openEditDaysModal.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)
        this._renderAllowedVacationsCell = this._renderAllowedVacationsCell.bind(
            this,
        )
        this.checkAll = this.checkAll.bind(this)
        this.filterRowWithSearchString = this.filterRowWithSearchString.bind(
            this,
        )
        this.createRows = this.createRows.bind(this)

        this.dictionary = dictionary
    }

    componentDidMount() {
        const {
            paidVacationSettings,
            absencesGroup,
            absenceLoading,
            groupMembers,
            groupPaidVacationsAllowed,
        } = this.props

        // Récupération des absences autorisées par années pour les membres du groupe
        if (
            paidVacationSettings &&
            Object.keys(paidVacationSettings).length > 0 &&
            !groupPaidVacationsAllowed &&
            absenceLoading === 0 &&
            groupMembers &&
            groupMembers.length > 0
        ) {
            this.props.dispatch(
                absencesActions.getUserLinkPaidVacationsAllowed(
                    groupMembers,
                    paidVacationSettings,
                ),
            )
        }

        if (absencesGroup && groupPaidVacationsAllowed?.length > 0) {
            this.createRows()
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            paidVacationSettings,
            groups,
            absencesGroup,
            absenceLoading,
            groupMembers,
            groupPaidVacationsAllowed,
            workTimeGroups,
            settings,
        } = this.props
        const { rows } = this.state

        // Récupération de toutes les absences pour les membres du groupe
        if (
            groups &&
            absenceLoading === 0 &&
            (prevProps.groups !== groups || !absencesGroup)
        ) {
            this.props.dispatch(
                absencesActions.getAllAbsencesForGroupMembers({
                    group: groups,
                }),
            )
        }

        if (
            absencesGroup &&
            groupPaidVacationsAllowed?.length > 0 &&
            (absencesGroup !== prevProps.absencesGroup ||
                rows.length === 0 ||
                groupPaidVacationsAllowed !==
                    prevProps.groupPaidVacationsAllowed)
        ) {
            this.createRows()
        }
    }

    render() {
        const { classes, absenceLoading } = this.props
        const { isFooterOpen, modaleSelectedUsers } = this.state
        const columns = [
            {
                field: 'user',
                headerName: this.displayText('user'),
            },
            {
                field: 'allowedVacations',
                headerName: this.displayText('allowedVacations'),
            },
            {
                field: 'used',
                headerName: this.displayText('used'),
            },
            {
                field: 'remainingVacations',
                headerName: this.displayText('remainingVacations'),
            },
        ]

        return (
            <>
                {this._renderSearchField()}
                {this._renderCurrentPeriod()}
                <div className={classes.tableContainer}>
                    {absenceLoading === 0
                        ? this._renderTable(columns)
                        : this.renderLoading(100, 100)}
                    {isFooterOpen && (
                        <div className={classes.footerBar}>
                            <BoatOnBlock
                                blockIds={62}
                                page
                                children={
                                    <Button
                                        className={classes.footerButton}
                                        size="medium"
                                        color="secondary"
                                        variant="contained"
                                        endIcon={<EditIcon />}
                                        onClick={this.openEditDaysModal}
                                    >
                                        {this.displayText(
                                            'editAllowedVacations',
                                        )}
                                    </Button>
                                }
                            />
                        </div>
                    )}
                </div>

                <BoatOnModal
                    openCondition={this.state.openedModal}
                    handleClose={this.handleCloseModal}
                    modalCores={{
                        allowedVacationsModal: (
                            <AllowedVacationsModal
                                selectedUsers={modaleSelectedUsers}
                                closeModal={this.handleCloseModal}
                            />
                        ),
                    }}
                    titles={{
                        allowedVacationsModal: this.displayText(
                            'leaveModalTitle',
                        ),
                    }}
                    fullWidth={false}
                />
            </>
        )
    }

    createRows() {
        const {
            paidVacationSettings,
            groups,
            absencesGroup,
            groupPaidVacationsAllowed,
            workTimeGroups,
        } = this.props

        let rows = absencesGroup.map(({ userLinkId, absences }) => {
            const userLink = groups.linkRGU.find(l => l.id === userLinkId)

            // Récupération du groupe de temps de travail dans lequel l'utilisateur se trouve
            // Sinon le groupe par défaut
            const workTimeSettings =
                workTimeGroups.find(wtg =>
                    wtg.userLinks.find(ul => ul.id === userLinkId),
                ) ?? workTimeGroups.find(wtg => wtg.byDefault)

            const userLinkAllowedVacations = groupPaidVacationsAllowed.find(
                gpa => gpa.userLinkId === userLinkId,
            ).value

            // Pour tous les utilisateurs du groupe
            // On récupère toutes les absences qu'il a eu pour toutes les années OU pour l'année en cours
            // Et on calcul le total de ces absences pour ces années
            //
            // Si le cumul des congés est autorisé on process toutes les années pour connaitre le nombre de congés accumulés au fil des années
            // Sinon on utilise seulement l'année en cours
            const allUserAbsences = userLinkAllowedVacations
                .filter(av => {
                    return (
                        paidVacationSettings?.allowPaidVacationAddition ||
                        av.year ===
                            worktimesUtils.getCurrentPeriodYear(
                                paidVacationSettings,
                            )
                    )
                })
                .map((av, i) => {
                    const absencesForYear = worktimesUtils
                        .filterAbsenceForPeriod(
                            absences,
                            av.year,
                            paidVacationSettings,
                        )
                        .filter(
                            // Uniquement les event validés et de type congés payés
                            a =>
                                a.absenceStatus.id === 2 &&
                                a.absenceReasonType.id === 1,
                        )

                    return {
                        year: av.year,
                        absences: absencesForYear,
                        allowed:
                            av.nbAllowedVacations *
                            60 *
                            DEFAULT_NB_HOURS_PER_DAY,
                        absenceTime: absencesForYear.reduce((acc, a) => {
                            return (
                                acc +
                                worktimesUtils.calculateAbsenceDuration(
                                    a,
                                    workTimeSettings,
                                )
                            )
                        }, 0),
                    }
                })

            const nbPaidVacationAllowed = worktimesUtils.getUserLinkAllowedVacations(
                userLinkAllowedVacations,
                paidVacationSettings,
            )

            // La colonne "Utilisés" affiche les congés payés posés et validés de la période en cours
            const used =
                allUserAbsences.find(
                    a =>
                        a.year ===
                        worktimesUtils.getCurrentPeriodYear(
                            paidVacationSettings,
                        ),
                )?.absenceTime ?? 0

            // La colonne "Congés payés restants" afficle les reste des congés payés de toutes les périodes
            const remainingVacations = allUserAbsences.reduce((acc, a) => {
                return acc + (a.allowed - a.absenceTime)
            }, 0)

            return {
                linkUserId: userLinkId,
                user: userLink.user ?? userLink.userSubscribe,
                nbPaidVacationAllowed,
                used: used,
                remainingVacations: remainingVacations,
            }
        })

        this.setState({
            rows: rows,
        })
    }

    openEditDaysModal() {
        this.setState({
            openedModal: 'allowedVacationsModal',
            modaleSelectedUsers: this.state.checkedUsers,
        })
    }

    handleCloseModal() {
        this.setState({
            openedModal: null,
            modaleSelectedUsers: [],
            checkedUsers: [],
            isFooterOpen: false,
        })
    }

    checkAll(event) {
        const { groupMembers } = this.props

        let newValue = []

        if (event.target.checked) {
            newValue = groupMembers.map(g => g.id)
        }

        this.setState({
            checkedUsers: newValue,
            isFooterOpen: newValue.length > 0,
        })
    }

    _renderSearchField() {
        const { classes } = this.props

        return (
            <TextField
                onChange={e => {
                    const { timer } = this.state
                    clearTimeout(timer)

                    const newTimer = setTimeout(() => {
                        this.setState({
                            searchString: e.target.value.trim().toUpperCase(),
                        })
                    }, 500)

                    this.setState({
                        timer: newTimer,
                    })
                }}
                variant="outlined"
                className={classes.searchField}
                placeholder={this.displayText('searchFieldPlaceholder')}
                onBlur={() => fixIosHeaderBug()}
            />
        )
    }

    _renderCurrentPeriod() {
        const { paidVacationSettings, classes } = this.props
        if (Object.keys(paidVacationSettings).length === 0) return <></>

        const { startDate, endDate } = worktimesUtils.getPeriodDates(
            paidVacationSettings,
        )
        return (
            <Typography className={classes.currentPeriod}>
                {this.displayText('currentPeriod')}:{' '}
                {startDate.format('DD/MM/YYYY')}
                {' - '}
                {endDate.format('DD/MM/YYYY')}
            </Typography>
        )
    }

    _renderTableHead(columns) {
        const { classes, groupMembers } = this.props
        const { checkedUsers } = this.state

        return (
            <TableHead
                classes={{
                    root: classes.tableHeader,
                }}
            >
                <TableRow>
                    <TableCell padding="checkbox">
                        {/* Checkbox de l'entête */}
                        {/* Permet de sélectionner tous les utilisateurs */}
                        <BoatOnBlock
                            blockIds={62}
                            page
                            children={
                                <Checkbox
                                    color="primary"
                                    checked={
                                        checkedUsers.length ===
                                        groupMembers.length
                                    }
                                    onClick={this.checkAll}
                                    inputProps={{
                                        'aria-label': this.displayText(
                                            'checkAllGroupMembers',
                                        ),
                                    }}
                                />
                            }
                        />
                    </TableCell>
                    <TableCell className={classes.mobileOnly}>
                        {this.displayText('selectAll')}
                    </TableCell>
                    {columns.map(c => {
                        return (
                            <TableCell
                                key={`header-${c.headerName}`}
                                className={`${classes.cell} ${classes.desktopOnly}`}
                            >
                                {c.headerName}
                            </TableCell>
                        )
                    })}
                </TableRow>
            </TableHead>
        )
    }

    _renderTableBody() {
        const { rows } = this.state

        return (
            <TableBody>
                {rows
                    .filter(this.filterRowWithSearchString)
                    .map(r => this._renderTableRow(r))}
            </TableBody>
        )
    }

    _renderTable(columns) {
        return (
            <Table>
                {this._renderTableHead(columns)}
                {this._renderTableBody()}
            </Table>
        )
    }

    _renderUserBadge(user) {
        const { classes } = this.props

        return (
            <div className={classes.badgeContainer}>
                {user.firstName ? (
                    <div className={classes.userBadge}>
                        {user.firstName[0] + user.lastName[0]}
                    </div>
                ) : (
                    <div className={classes.userBadge}>
                        <PersonIcon className={classes.userBadgeIcon} />
                    </div>
                )}
                {user.firstName && user.lastName
                    ? user.firstName + ' ' + user.lastName
                    : user.mail}
            </div>
        )
    }

    _renderAllowedVacationsCell(rowData) {
        return (
            <>
                {rowData.nbPaidVacationAllowed}
                <BoatOnBlock
                    blockIds={62}
                    page
                    children={
                        <IconButton
                            size={`small`}
                            id={`editCell`}
                            onClick={() => {
                                this.setState({
                                    openedModal: 'allowedVacationsModal',
                                    modaleSelectedUsers: [rowData.linkUserId],
                                })
                            }}
                            children={<EditIcon />}
                        />
                    }
                />
            </>
        )
    }

    _renderTableRow(rowData) {
        const { checkedUsers } = this.state
        const { classes } = this.props

        const {
            days: remainingVacationsDays,
            hours: remainingVacationsHours,
            minutes: remainingVacationsMinutes,
        } = worktimesUtils.minutesToWorkingDays(rowData.remainingVacations)

        const {
            days: usedVacationsDays,
            hours: usedVacationsHours,
            minutes: usedVacationsMinutes,
        } = worktimesUtils.minutesToWorkingDays(rowData.used)

        return (
            <TableRow key={`row-${rowData.linkUserId} ${classes.desktopOnly}`}>
                <TableCell padding="checkbox">
                    <BoatOnBlock
                        blockIds={62}
                        page
                        children={
                            <Checkbox
                                color="primary"
                                checked={
                                    checkedUsers.indexOf(rowData.linkUserId) !==
                                    -1
                                }
                                onClick={e => {
                                    let newCheckedUsers = [...checkedUsers]
                                    if (
                                        newCheckedUsers.indexOf(
                                            rowData.linkUserId,
                                        ) === -1
                                    ) {
                                        newCheckedUsers.push(rowData.linkUserId)
                                    } else {
                                        newCheckedUsers.splice(
                                            newCheckedUsers.indexOf(
                                                rowData.linkUserId,
                                            ),
                                            1,
                                        )
                                    }

                                    this.setState({
                                        checkedUsers: newCheckedUsers,
                                        isFooterOpen:
                                            newCheckedUsers.length > 0,
                                    })
                                }}
                            />
                        }
                    />
                </TableCell>
                <TableCell className={`${classes.cell} ${classes.desktopOnly}`}>
                    {this._renderUserBadge(rowData.user)}
                </TableCell>
                <TableCell
                    className={`${classes.cell} ${classes.desktopOnly}`}
                    classes={{
                        root: classes.nbPaidVacationAllowedCell,
                    }}
                >
                    {this._renderAllowedVacationsCell(rowData)}
                </TableCell>
                <TableCell className={`${classes.cell} ${classes.desktopOnly}`}>
                    {`${usedVacationsDays} ${this.displayText(
                        'shortDay',
                    )} ${usedVacationsHours}h ${usedVacationsMinutes}m`}
                </TableCell>
                <TableCell className={`${classes.cell} ${classes.desktopOnly}`}>
                    {`${remainingVacationsDays} ${this.displayText(
                        'shortDay',
                    )} ${remainingVacationsHours}h ${remainingVacationsMinutes}m`}
                </TableCell>
                {/* <TableCell>
                        <EditIcon />
                    </TableCell> */}

                <TableCell
                    className={classes.mobileOnly}
                    style={{
                        paddingLeft: 0,
                    }}
                >
                    <div className={classes.mobileCell}>
                        {this._renderUserBadge(rowData.user)}
                        <strong>
                            {this._renderAllowedVacationsCell(rowData)}
                            {this.displayText('allowedDays')}
                        </strong>
                        <div className={classes.mobileRow}>
                            <div>
                                {`${usedVacationsDays} ${this.displayText(
                                    'shortDay',
                                )} ${usedVacationsHours}h ${usedVacationsMinutes}m ${this.displayText(
                                    'used',
                                ).toLowerCase()}`}
                            </div>
                            <strong>
                                {`
                                    ${remainingVacationsDays} ${this.displayText(
                                    'shortDay',
                                )} ${remainingVacationsHours}h ${remainingVacationsMinutes}m ${this.displayText(
                                    'remaining',
                                ).toLowerCase()}
                                `}
                            </strong>
                        </div>
                    </div>
                </TableCell>
            </TableRow>
        )
    }

    _renderTableRowMobile(rowData) {
        const { checkedUsers } = this.state
        const { classes } = this.props

        const {
            days: remainingVacationsDays,
            hours: remainingVacationsHours,
            minutes: remainingVacationsMinutes,
        } = worktimesUtils.minutesToWorkingDays(rowData.remainingVacations)

        const {
            days: usedVacationsDays,
            hours: usedVacationsHours,
            minutes: usedVacationsMinutes,
        } = worktimesUtils.minutesToWorkingDays(rowData.used)

        return (
            <TableRow key={`row-${rowData.linkUserId}`}>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        checked={
                            checkedUsers.indexOf(rowData.linkUserId) !== -1
                        }
                        onClick={e => {
                            const newCheckedUsers = [...checkedUsers]
                            if (
                                newCheckedUsers.indexOf(rowData.linkUserId) ===
                                -1
                            ) {
                                newCheckedUsers.push(rowData.linkUserId)
                            } else {
                                newCheckedUsers.splice(
                                    newCheckedUsers.indexOf(rowData.linkUserId),
                                    1,
                                )
                            }

                            this.setState({
                                checkedUsers: newCheckedUsers,
                            })
                        }}
                    />
                </TableCell>
                <TableCell>
                    <div className={classes.mobileCell}>
                        {this._renderUserBadge(rowData.user)}
                        <strong>
                            {this._renderAllowedVacationsCell(rowData)}
                            {this.displayText('allowedDays')}
                        </strong>
                        <div className={classes.mobileRow}>
                            <div>
                                {`${usedVacationsDays} ${this.displayText(
                                    'shortDay',
                                )} ${usedVacationsHours}h ${usedVacationsMinutes}m ${this.displayText(
                                    'used',
                                ).toLowerCase()}`}
                            </div>
                            <strong>
                                {`
                                    ${remainingVacationsDays} ${this.displayText(
                                    'shortDay',
                                )} ${remainingVacationsHours}h ${remainingVacationsMinutes}m ${this.displayText(
                                    'remaining',
                                ).toLowerCase()}
                                `}
                            </strong>
                        </div>
                    </div>
                </TableCell>
            </TableRow>
        )
    }

    filterRowWithSearchString(r) {
        const { searchString } = this.state

        return (
            r.user.firstName
                ?.trim()
                ?.concat(` ${r.user.lastName}`)
                ?.toUpperCase()
                ?.indexOf(searchString) > -1 ||
            r.user.lastName
                ?.trim()
                ?.concat(` ${r.user.firstName}`)
                ?.toUpperCase()
                ?.indexOf(searchString) > -1 ||
            r.user.email
                ?.trim()
                ?.toUpperCase()
                ?.indexOf(searchString) > -1 ||
            r.user.mail
                ?.trim()
                ?.toUpperCase()
                ?.indexOf(searchString) > -1
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.authentication.user,
        groups: state.group.groupsMembers,
        currentGroupId: state.group.currentGroupId,
        paidVacationSettings: state.settings.paidVacationSettings,
        settingsLoading: state.settings.loading,
        groupMembers: state.group?.groupsMembers?.linkRGU || [],
        subscriptions: state.group?.groupsMembers?.user?.sub || [],
        absences: state.absence.absences,
        absencesGroup: state.absence.absencesGroup,
        absenceLoading: state.absence.loading,
        groupLoading: state.group.loading,
        groupPaidVacationsAllowed: state.absence.paidVacationsAllowed,
        workTimeGroups: state.settings.workTimeSettings,
    }
}

export default connect(mapStateToProps)(
    withStyles(styles)(UserPaidVacationsTab),
)
