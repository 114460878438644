import React from 'react'
import { connect } from 'react-redux'
import BoatOnModalCore from '../../../../../common/BoatOnModalCore'
import BoatOnNumberField from '../../../../../common/UsefullComponents/BoatOnNumberField'
import InputAdornment from '@material-ui/core/InputAdornment'

import dictionary from './Dictionary/EditLevelsModalDico'

import { withStyles } from '@material-ui/core/styles'
import styles from './Styles/EditLevelsModalCss'
import { editNavigation } from '../../../../../../actions'
import { getFillingLevels } from '../../Utils/eventsFilter'
import { getTranslatedTitleFromEvent, isSafariIOS } from '../../../Utils'

class EditLevelsModal extends BoatOnModalCore {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
        this.state = {
            equipmentCapacities: [],
            navigation: null,
            userEquipmentsUsed: [],
            updated: false,
        }

        this.onChangeLevel = this.onChangeLevel.bind(this)
        this.save = this.save.bind(this)
    }

    componentDidMount() {
        const { navigation } = this.props

        this._initState()

        this.setState({ navigation: JSON.parse(JSON.stringify(navigation)) })
    }

    _initState() {
        const { events, userEquipments } = this.props
        const fillingLevels = getFillingLevels(events, userEquipments)

        this.setState({
            equipmentCapacities: fillingLevels.bobEventUsed,
            userEquipmentsUsed: fillingLevels.userEquipmentsUsed,
        })
    }

    save() {
        const { navigation } = this.state
        const { dispatch, onClose } = this.props

        navigation.fillingLevels.forEach(
            item => (item.level = parseInt(item.level)),
        )

        dispatch(editNavigation(navigation))
        onClose()
    }

    onChangeLevel(value, equipmentId) {
        const { navigation } = this.state
        const index = navigation.fillingLevels.findIndex(
            item => equipmentId === item.equipment.id,
        )

        if (index === -1) {
            navigation.fillingLevels.push({
                equipment: {
                    id: equipmentId,
                },
                level: value,
            })
        } else {
            navigation.fillingLevels[index].level = value
        }

        this.setState({
            navigation,
            updated: true,
        })
    }

    _renderBody() {
        const { classes, context } = this.props
        const {
            navigation,
            userEquipmentsUsed,
            equipmentCapacities,
        } = this.state

        const renderStockLevel = equipmentCapacities
            .sort((a, b) => {
                const aName = getTranslatedTitleFromEvent(this, a)
                const bName = getTranslatedTitleFromEvent(this, b)

                if (!aName) return 1
                if (!bName) return -1

                return aName.localeCompare(bName)
            })
            .map((event, index) => {
                const equipment = event.detail?.equipment || null

                if (!equipment) return null

                const imgSrc = equipment.equipmentFamily?.type?.image
                    ? require(`images/${equipment.equipmentFamily?.type?.image}`)
                    : null
                const familyType =
                    equipment.equipmentFamily?.type?.translation[context] || ''
                const value = navigation.fillingLevels.find(
                    item => item.equipment.id === equipment.id,
                )?.level

                const equipmentDefault = userEquipmentsUsed.find(
                    item =>
                        item.equipmentType.id === equipment.equipmentType.id,
                )
                const unit =
                    equipmentDefault.equipmentType.optionnalDetailActivated
                        ?.unit
                const capacity = event.detail.optionnalDetail?.capacity
                const capacityRemaining = (value * capacity) / 100

                return (
                    <div
                        className={classes.stockContainer}
                        key={`stocklevelItem-${index}`}
                    >
                        <div className={classes.stockIconContainer}>
                            <img
                                src={imgSrc}
                                alt={familyType}
                                className={classes.stockIcon}
                            />
                        </div>
                        <div className={classes.stockVerticalContainer}>
                            {getTranslatedTitleFromEvent(this, event)}
                            <div className={classes.stockRowContainer}>
                                <BoatOnNumberField
                                    label={this.displayText('actualLevel')}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                %
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="outlined"
                                    margin="dense"
                                    onChange={e =>
                                        this.onChangeLevel(
                                            e.target.value,
                                            equipment.id,
                                        )
                                    }
                                    className={classes.stockInput}
                                    value={value}
                                    max={100}
                                    min={0}
                                    isDecimal
                                />
                                <div className={classes.stockInfoContainer}>
                                    <div className={classes.stockInfo}>
                                        {capacity ? (
                                            `${this.displayText(
                                                'capacity',
                                            )} ${capacity} ${unit}`
                                        ) : (
                                            <div
                                                className={classes.noCapacity}
                                                title={this.displayText(
                                                    'missingCapacity',
                                                )}
                                            >
                                                {this.displayText('capacity')} ?{' '}
                                                {unit}
                                            </div>
                                        )}
                                    </div>
                                    <div className={classes.stockInfo}>
                                        {capacity ? (
                                            `${capacityRemaining} ${unit}`
                                        ) : (
                                            <div
                                                className={classes.noCapacity}
                                                title={this.displayText(
                                                    'missingCapacity',
                                                )}
                                            >
                                                ? {unit}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })

        return (
            <div
                className={`${classes.root} ${
                    isSafariIOS() ? classes.iosRoot : ''
                }`}
            >
                <div className={classes.title}>
                    {this.displayText('titleLevels')}
                </div>
                <div className={classes.stockWrapper}>{renderStockLevel}</div>
            </div>
        )
    }

    render() {
        const { title, noCross = true, showFollowButton } = this.props

        const { updated } = this.state

        return (
            <>
                {this._renderTitle(
                    title,
                    noCross,
                    null,
                    undefined,
                    showFollowButton,
                )}
                {this._renderBody({
                    body: this._renderBody(),
                })}
                {this._renderActions([
                    {
                        action: this.save,
                        disabled: !updated,
                        label: this.displayText('save'),
                    },
                ])}
            </>
        )
    }
}

function mapStateToProps(state) {
    const url = window.location.pathname
    const id = url.split('/').slice(-1)[0]
    const navigation = state.logbook.navigations.find(
        nav => nav.id === parseInt(id),
    )

    return {
        navigation,
        events: state.bob?.events || [],
        userEquipments: state.types?.userEquipments || [],
    }
}

export default connect(mapStateToProps)(withStyles(styles)(EditLevelsModal))
