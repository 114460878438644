import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Slider from '@material-ui/core/Slider'
import styles from './Styles/RangeInputCss'
import { SliderStyle } from './Styles/StyledSliderCss'
import InputAdornment from '@material-ui/core/InputAdornment'
import BoatOnNumberField from './BoatOnNumberField'

const StyledSlider = withStyles(SliderStyle)(Slider)

function RangeInput({
    classes,
    label1,
    label2,
    min,
    max,
    onChange,
    step,
    unity,
    value,
}) {
    const [valueRange, setValue] = React.useState(value) // value doit être de type int ou float

    const handleChange = newValue => {
            setValue(newValue)
            onChange([newValue[0], newValue[1]])
    }

    return (
        <Paper className={classes.root}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginTop: '15px',
                        marginBottom: '5px',
                    }}
                >
                    <BoatOnNumberField
                        className={classes.text}
                        style={{ marginRight: '30px' }}
                        type="text"
                        variant="outlined"
                        value={valueRange[0]}
                        label={label1}
                        placeholder={min.toString()}
                        onChange={e => {
                            handleChange([e.target.value, valueRange[1]])
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{ min: min, max: max, step: step }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {unity}
                                </InputAdornment>
                            ),
                            classes: { input: classes.paddingChange },
                        }}
                    />
                    <BoatOnNumberField
                        className={classes.text}
                        style={{ marginLeft: '10px' }}
                        type="text"
                        variant="outlined"
                        value={valueRange[1]}
                        placeholder={max.toString()}
                        label={label2}
                        onChange={e => {
                            handleChange([valueRange[0], e.target.value])
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{ min: min, max: max, step: step }}
                        InputProps={{
                            step: step,
                            endAdornment: (
                                <InputAdornment position="end">
                                    {unity}
                                </InputAdornment>
                            ),
                            classes: { input: classes.paddingChange },
                        }}
                    />
                </div>
                <StyledSlider
                    value={[
                        parseFloat(valueRange[0]) || min,
                        parseFloat(valueRange[1]) || max,
                    ]}
                    onChange={(_, newValue) => handleChange(newValue)}
                    defaultValue={[min, max]}
                    min={min}
                    max={max}
                    step={step}
                />
            </div>
        </Paper>
    )
}

export default withStyles(styles)(RangeInput)
