import React from 'react'
import { withStyles } from '@material-ui/styles'
import dictionary from './Dictionary/WorkTimeSettingPageDico'
import styles from './Styles/WorkTimeSettingPageCss'
import BoatOnComponent from '../../../common/BoatOnComponent'
import {
    Typography,
    Divider,
    Checkbox,
    TextField,
    Button,
    IconButton,
    Switch,
    Select,
    MenuItem,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import BoatOnAlert from '../../../common/UsefullComponents/BoatOnAlert'
import { Button as BoatOnButton } from '../../../common/BoatOnButton'
import { settingsActions } from '../../../../actions/bob/settings.actions'
import { connect } from 'react-redux'
import EditIcon from '@mui/icons-material/Edit'
import AddIcon from '@mui/icons-material/Add'
import UserSelector from '../../../common/UserSelector/UserSelector'
import PersonIcon from '@mui/icons-material/Person'
import anchorLoader from '../../../../images/anchorLoader.gif'
import { MobileTimePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { history } from '../../../../helpers'
import AppRoute from '../../../../constants/AppRoute'
import BoatOnNumberField from '../../../common/UsefullComponents/BoatOnNumberField'
import BoatOnBlock from '../../../common/Blocks/BoatOnBlock'

class WorkTimeSettingPage extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
        this.state = {
            selectedGroupIndex: null,
            minimumRestTime: 11,
            isMinimumRestTimeSet: true,
            maxWorkTime: 12,
            isMaxWorkTimeSet: true,
            groups: [],
            isInEditMode: false,
        }

        this.NB_BADGES = 3

        this.DEFAULT_DAY_SETTING = {
            startTime: '08:00:00',
            endTime: '17:00:00',
            breakTimes: [
                {
                    startTime: '12:00:00',
                    endTime: '13:00:00',
                },
            ],
        }

        this.addGroup = this.addGroup.bind(this)
        this.updateDefaultGroupMembers = this.updateDefaultGroupMembers.bind(
            this,
        )
        this.deleteGroup = this.deleteGroup.bind(this)
        this.saveSettings = this.saveSettings.bind(this)
        this._renderUserSelector = this._renderUserSelector.bind(this)
        this._renderUserBadge = this._renderUserBadge.bind(this)
    }

    componentDidMount() {
        const { currentGroupId } = this.props

        if (currentGroupId) {
            this.props.dispatch(
                settingsActions.getGroupWorkTimeSettings({
                    groupId: currentGroupId,
                }),
            )
        }
    }

    componentDidUpdate(prevProps) {
        const {
            currentGroupId,
            loading,
            workTimeGroups,
            subscriptions,
            groupLoading,
            user,
            groupMembers,
        } = this.props

        const { selectedGroupIndex } = this.state

        // Redirection vers l'inventaire si l'utilisateur n'a pas les droits
        if (subscriptions.length === 0 && groupLoading < 1) {
            this.historyPush(history, AppRoute.LogBook.Inventory)
        }

        if (currentGroupId !== prevProps.currentGroupId) {
            this.props.dispatch(
                settingsActions.getGroupWorkTimeSettings({
                    groupId: currentGroupId,
                }),
            )
        }

        if (loading === 0 && prevProps.loading !== 0) {
            if (workTimeGroups.length === 0) {
                // load default group
                this.addGroup()
            } else {
                //workaround
                workTimeGroups.forEach(group => {
                    if (group.mondaySetting === null) {
                        group.mondaySetting = {
                            startTime: '08:00:00',
                            endTime: '17:00:00',
                            breakTimes: [],
                            selected: false,
                        }
                    }
                    if (group.tuesdaySetting === null) {
                        group.tuesdaySetting = {
                            startTime: '08:00:00',
                            endTime: '17:00:00',
                            breakTimes: [],
                            selected: false,
                        }
                    }
                    if (group.wednesdaySetting === null) {
                        group.wednesdaySetting = {
                            startTime: '08:00:00',
                            endTime: '17:00:00',
                            breakTimes: [],
                            selected: false,
                        }
                    }
                    if (group.thursdaySetting === null) {
                        group.thursdaySetting = {
                            startTime: '08:00:00',
                            endTime: '17:00:00',
                            breakTimes: [],
                            selected: false,
                        }
                    }

                    if (group.fridaySetting === null) {
                        group.fridaySetting = {
                            startTime: '08:00:00',
                            endTime: '17:00:00',
                            breakTimes: [],
                            selected: false,
                        }
                    }
                    if (group.saturdaySetting === null) {
                        group.saturdaySetting = {
                            startTime: '08:00:00',
                            endTime: '17:00:00',
                            breakTimes: [],
                            selected: false,
                        }
                    }
                    if (group.sundaySetting === null) {
                        group.sundaySetting = {
                            startTime: '08:00:00',
                            endTime: '17:00:00',
                            breakTimes: [],
                            selected: false,
                        }
                    }

                    group.mondaySetting.label = 'monday'
                    group.tuesdaySetting.label = 'tuesday'
                    group.wednesdaySetting.label = 'wednesday'
                    group.thursdaySetting.label = 'thursday'
                    group.fridaySetting.label = 'friday'
                    group.saturdaySetting.label = 'saturday'
                    group.sundaySetting.label = 'sunday'
                })

                this.setState({
                    groups: workTimeGroups,
                })
            }
        }
        if (
            groupMembers &&
            groupMembers.length > 0 &&
            // workTimeGroups &&
            selectedGroupIndex === null
        ) {
            // Sélection du groupe de travail dans lequel se trouve l'utilisateur
            let index = 0
            const userLink = groupMembers.find(
                gm =>
                    gm?.user?.email === user.email ||
                    gm?.userSubscribe?.mail === user.email,
            )
            if (userLink && workTimeGroups.length > 0) {
                index = workTimeGroups.findIndex(wtg =>
                    wtg.userLinks.find(ul => ul.id === userLink.id),
                )
            }
            this.setState({
                selectedGroupIndex: index !== -1 ? index : 0,
            })
        }
    }

    addGroup() {
        const _groups = [...this.state.groups]

        let group = {
            groupName: `${this.displayText('group')} ${_groups.length + 1}`,
            byDefault: _groups.find(group => group.byDefault) ? false : true,
            dailyLimitTime: '12:00:00',
            minimumRestTime: '12:00:00',
            userLinks: [],
            mondaySetting: {
                label: 'monday',
                ...this.DEFAULT_DAY_SETTING,
            },
            tuesdaySetting: {
                label: 'tuesday',
                ...this.DEFAULT_DAY_SETTING,
            },
            wednesdaySetting: {
                label: 'wednesday',
                ...this.DEFAULT_DAY_SETTING,
            },
            thursdaySetting: {
                label: 'thursday',
                ...this.DEFAULT_DAY_SETTING,
            },
            fridaySetting: {
                label: 'friday',
                ...this.DEFAULT_DAY_SETTING,
            },
            saturdaySetting: {
                label: 'saturday',
                startTime: '08:00:00',
                endTime: '17:00:00',
                breakTimes: [],
            },
            sundaySetting: {
                label: 'sunday',
                startTime: '08:00:00',
                endTime: '17:00:00',
                breakTimes: [],
            },
        }

        _groups.push(group)

        this.setState(
            {
                groups: _groups,
                selectedGroupIndex: _groups.length - 1,
                isInEditMode: true,
            },
            () => {
                this.updateDefaultGroupMembers()
            },
        )
    }

    updateDefaultGroupMembers() {
        const { groups } = this.state
        const { groupMembers } = this.props

        const defaultGroup = groups.find(g => g.byDefault === true)
        if (!defaultGroup) {
            this.addGroup()
        }

        const userLinks = groupMembers.filter(gm => {
            let r = false
            groups.forEach(group => {
                if (group.userLinks.find(ul => ul.id === gm.id)) {
                    r = false
                } else {
                    r = true
                }
            })
            return r
        })
    }

    async deleteGroup() {
        const { groups, currentGroupId, selectedGroupIndex } = this.state

        const selectedGroup = groups[selectedGroupIndex]

        const _groups = [...groups]

        if (!selectedGroup.id) {
            _groups.splice(selectedGroupIndex, 1)
        }
        if (selectedGroup.id && !selectedGroup?.byDefault) {
            await this.props.dispatch(
                settingsActions.deleteGroupWorkTimeSettings({
                    groupSettingId: selectedGroup.id,
                }),
            )
            settingsActions.getGroupWorkTimeSettings({
                groupId: currentGroupId,
            })
        }

        this.setState({
            selectedGroupIndex: 0,
            groups: _groups,
        })
    }

    saveSettings() {
        const { groups, selectedGroupIndex } = this.state

        const { currentGroupId } = this.props

        const selectedGroup = groups[selectedGroupIndex]

        const body = {
            byDefault: selectedGroup?.byDefault,
            groupName: selectedGroup?.groupName || 'default',
            dailyLimitTime: selectedGroup.dailyLimitTime,
            minimumRestTime: selectedGroup.minimumRestTime,
            userLinks: selectedGroup?.userLinks
                ? selectedGroup.userLinks.map(userLink => ({
                      id: userLink.id,
                  }))
                : [],
            mondaySetting:
                selectedGroup.mondaySetting.selected !== false
                    ? selectedGroup.mondaySetting
                    : null,
            tuesdaySetting:
                selectedGroup.tuesdaySetting.selected !== false
                    ? selectedGroup.tuesdaySetting
                    : null,
            wednesdaySetting:
                selectedGroup.wednesdaySetting.selected !== false
                    ? selectedGroup.wednesdaySetting
                    : null,
            thursdaySetting:
                selectedGroup.thursdaySetting.selected !== false
                    ? selectedGroup.thursdaySetting
                    : null,
            fridaySetting:
                selectedGroup.fridaySetting.selected !== false
                    ? selectedGroup.fridaySetting
                    : null,
            saturdaySetting:
                selectedGroup.saturdaySetting.selected !== false
                    ? selectedGroup.saturdaySetting
                    : null,
            sundaySetting:
                selectedGroup.sundaySetting.selected !== false
                    ? selectedGroup.sundaySetting
                    : null,
        }

        if (selectedGroup.id === undefined) {
            this.props.dispatch(
                settingsActions.postGroupWorkTimeSettings({
                    groupId: currentGroupId,
                    body,
                }),
            )
        } else {
            this.props.dispatch(
                settingsActions.putGroupWorkTimeSettings({
                    groupSettingId: selectedGroup.id,
                    groupId: currentGroupId,
                    body,
                }),
            )
        }
    }

    _handleChangeDayArray(value, field, key) {
        const { groups, selectedGroupIndex } = this.state

        const _groups = [...groups]

        if (field === 'startTime' || field === 'endTime')
            _groups[selectedGroupIndex][key][field] = value.format('HH:mm')
        else _groups[selectedGroupIndex][key][field] = value

        if (field === 'selected' && value) {
            _groups[selectedGroupIndex][key].breakTimes = [
                {
                    startTime: '12:00:00',
                    endTime: '13:00:00',
                },
            ]
            _groups[selectedGroupIndex][key].breakTimes.selected = true
        } else if (field === 'selected' && !value) {
            _groups[selectedGroupIndex][key].breakTimes = []
            _groups[selectedGroupIndex][key].breakTimes.selected = false
        }

        this.setState({ groups: _groups })
    }

    _handleChangeBreak(value, field, breakIndex, key) {
        const { groups, selectedGroupIndex } = this.state
        const _groups = [...groups]

        _groups[selectedGroupIndex][key].breakTimes[breakIndex][
            field
        ] = value.format('HH:mm')

        this.setState({ groups: _groups })
    }

    _addBreak(key) {
        const { groups, selectedGroupIndex } = this.state
        const _groups = [...groups]

        _groups[selectedGroupIndex][key].breakTimes.push({
            startTime: '12:00:00',
            endTime: '13:00:00',
        })

        this.setState({ groups: _groups })
    }

    _deleteBreak(key, breakIndex) {
        const { groups, selectedGroupIndex } = this.state
        const _groups = [...groups]

        _groups[selectedGroupIndex][key].breakTimes = _groups[
            selectedGroupIndex
        ][key].breakTimes.filter((_, i) => i !== breakIndex)

        this.setState({ groups: _groups })
    }

    _displayButtonAddBreak(breakIndex, key) {
        const { classes } = this.props
        const { groups, selectedGroupIndex } = this.state

        if (breakIndex === 0)
            return (
                <Button
                    variant="text"
                    onClick={() => this._addBreak(key)}
                    color="primary"
                    classes={{
                        root: classes.textButton,
                        disabled: `${classes.disabled} ${classes.primaryColor}`,
                    }}
                    startIcon={<AddIcon />}
                    disabled={
                        groups[selectedGroupIndex][key].selected === false
                    }
                >
                    {this.displayText('addBreak')}
                </Button>
            )

        return <div className={classes.emptyDiv} />
    }

    _displayBreaks(breaks, key, dayIndex) {
        const { classes } = this.props

        if (!breaks || breaks.length === 0)
            return <>{this._displayButtonAddBreak(0, key)}</>

        return breaks.map((dayBreak, breakIndex) => {
            const startTime = dayjs(
                dayBreak.startTime
                    ? `${dayBreak.startTime.split(':', 2)[0]}:${
                          dayBreak.startTime.split(':', 2)[1]
                      }`
                    : '08:00',
                'HH:mm',
            )
            const endTime = dayjs(
                dayBreak.endTime
                    ? `${dayBreak.endTime.split(':', 2)[0]}:${
                          dayBreak.endTime.split(':', 2)[1]
                      }`
                    : '17:00',
                'HH:mm',
            )

            return (
                <div className={classes.inline} key={`#${breakIndex + 1}`}>
                    {this._displayButtonAddBreak(breakIndex, key)}
                    <div className={classes.break}>
                        <Typography
                            style={{ marginRight: 10 }}
                        >{`#${breakIndex + 1}`}</Typography>
                        <div className={classes.inlineBreak}>
                            <div className={classes.col}>
                                <MobileTimePicker
                                    label={this.displayText(`startBreak`)}
                                    ampm={false}
                                    value={startTime}
                                    onChange={e =>
                                        this._handleChangeBreak(
                                            e,
                                            'startTime',
                                            breakIndex,
                                            key,
                                        )
                                    }
                                    required
                                    slotProps={{
                                        textField: {
                                            classes: {
                                                root: classes.breakTextField,
                                            },
                                        },
                                    }}
                                />
                            </div>
                            <div className={classes.col}>
                                <MobileTimePicker
                                    label={this.displayText(`endBreak`)}
                                    ampm={false}
                                    value={endTime}
                                    onChange={e =>
                                        this._handleChangeBreak(
                                            e,
                                            'endTime',
                                            breakIndex,
                                            key,
                                        )
                                    }
                                    required
                                    slotProps={{
                                        textField: {
                                            classes: {
                                                root: classes.breakTextField,
                                            },
                                        },
                                    }}
                                />
                            </div>
                        </div>
                        <IconButton
                            color="inherit"
                            onClick={() => this._deleteBreak(key, breakIndex)}
                        >
                            <DeleteIcon fontSize="small" />
                        </IconButton>
                    </div>
                </div>
            )
        })
    }

    _displayDayLine(dayElement, key, dayIndex) {
        const { classes } = this.props
        const { groups, selectedGroupIndex } = this.state
        if (!dayElement) return <></>

        const startTime = dayjs(
            dayElement.startTime
                ? `${dayElement.startTime.split(':', 2)[0]}:${
                      dayElement.startTime.split(':', 2)[1]
                  }`
                : '08:00',
            'HH:mm',
        )

        const endTime = dayjs(
            dayElement.endTime
                ? `${dayElement.endTime.split(':', 2)[0]}:${
                      dayElement.endTime.split(':', 2)[1]
                  }`
                : '17:00',
            'HH:mm',
        )

        return (
            <div className={classes.row} key={dayElement.label}>
                <div className={classes.daySetting}>
                    <div className={classes.day}>
                        <Checkbox
                            checked={
                                groups[selectedGroupIndex][key].selected !==
                                false
                            }
                            color="primary"
                            onClick={e =>
                                this._handleChangeDayArray(
                                    e.target.checked,
                                    'selected',
                                    key,
                                )
                            }
                            style={{ marginLeft: -10 }}
                        />
                        <Typography
                            className={
                                groups[selectedGroupIndex][key].selected !==
                                false
                                    ? ''
                                    : classes.disabled
                            }
                        >
                            {this.displayText(dayElement.label)}
                        </Typography>
                    </div>
                    <div className={classes.inlineDay}>
                        <div className={classes.col}>
                            <MobileTimePicker
                                label={this.displayText('startDay')}
                                ampm={false}
                                value={startTime}
                                onChange={e =>
                                    this._handleChangeDayArray(
                                        e,
                                        'startTime',
                                        key,
                                    )
                                }
                                disabled={
                                    groups[selectedGroupIndex][key].selected ===
                                    false
                                }
                                slotProps={{
                                    textField: {
                                        classes: {
                                            root: classes.dayTextField,
                                        },
                                    },
                                }}
                            />
                        </div>
                        <div className={classes.col}>
                            <MobileTimePicker
                                label={this.displayText('endDay')}
                                ampm={false}
                                value={endTime}
                                classes={{}}
                                onChange={e =>
                                    this._handleChangeDayArray(
                                        e,
                                        'endTime',
                                        key,
                                    )
                                }
                                disabled={
                                    groups[selectedGroupIndex][key].selected ===
                                    false
                                }
                                slotProps={{
                                    textField: {
                                        classes: {
                                            root: classes.dayTextField,
                                        },
                                    },
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className={classes.breaks}>
                    {this._displayBreaks(dayElement.breakTimes, key, dayIndex)}
                </div>
                <Divider
                    orientation={`horizontal`}
                    className={classes.mobileDivider}
                />
            </div>
        )
    }

    _displayDays() {
        const { classes } = this.props
        const { groups, selectedGroupIndex } = this.state

        if (!groups[selectedGroupIndex]) return <></>

        let dayIndex = -1

        return (
            <section className={classes.section}>
                {Object.keys(groups[selectedGroupIndex]).map(key => {
                    if (key.includes('Setting')) {
                        dayIndex++
                        return this._displayDayLine(
                            groups[selectedGroupIndex][key],
                            key,
                            dayIndex,
                        )
                    }
                    return <></>
                })}
            </section>
        )
    }

    _handleChange(value, key) {
        const { groups, selectedGroupIndex } = this.state

        const _groups = [...groups]

        // if (key === `isMaxWorkTimeSet`) {
        //     _groups[selectedGroupIndex].dailyLimitTime = undefined
        // } else {
        _groups[selectedGroupIndex][key] = value
        // }

        this.setState({
            groups: _groups,
        })
    }

    _displayHours() {
        const { classes } = this.props
        const {
            isMaxWorkTimeSet,
            isMinimumRestTimeSet,
            groups,
            selectedGroupIndex,
        } = this.state

        let dailyLimitTime = groups[selectedGroupIndex]?.dailyLimitTime?.split(
            ':',
        )[0]

        let minimumRestTime = groups[
            selectedGroupIndex
        ]?.minimumRestTime?.split(':')[0]

        return (
            <section className={classes.section}>
                <div className={classes.row} style={{ marginBottom: 5 }}>
                    <Typography style={{ minWidth: 330 }}>
                        {this.displayText(`maxWorkTime`)}
                    </Typography>
                    <div className={classes.inline}>
                        <Switch
                            checked={dailyLimitTime !== '00'}
                            name="dailyLimitTime"
                            color="primary"
                            onChange={e =>
                                this._handleChange(
                                    dailyLimitTime === '00'
                                        ? '12:00:00'
                                        : '00:00:00',
                                    'dailyLimitTime',
                                )
                            }
                            classes={{
                                root: classes.switchRoot,
                            }}
                        />
                        {dailyLimitTime !== '00' && (
                            <>
                                <BoatOnNumberField
                                    isDecimal
                                    variant="outlined"
                                    margin="dense"
                                    value={dailyLimitTime}
                                    type="number"
                                    onKeyDown={e => {
                                        if (
                                            e.key === 'e' ||
                                            e.key === 'E' ||
                                            e.key === '-' ||
                                            e.key === '+'
                                        ) {
                                            e.preventDefault()
                                        }
                                    }}
                                    onChange={e => {
                                        if (
                                            e.target.value > 0 &&
                                            e.target.value <= 24
                                        )
                                            this._handleChange(
                                                `${e.target.value}:00:00`,
                                                'dailyLimitTime',
                                            )
                                    }}
                                    style={{
                                        width: 65,
                                        marginLeft: 30,
                                        marginRight: 10,
                                    }}
                                />
                                <Typography
                                    className={
                                        isMaxWorkTimeSet ? '' : classes.disabled
                                    }
                                >
                                    {this.displayText(`hours`)}
                                </Typography>
                            </>
                        )}
                    </div>
                </div>
                <div className={classes.row}>
                    <Typography
                        style={{ minWidth: 330 }}
                        className={classes.mobileSpace}
                    >
                        {this.displayText(`minimumRestTime`)}
                    </Typography>
                    <div className={classes.inline}>
                        <Switch
                            checked={minimumRestTime !== '00'}
                            name="minimumRestTime"
                            color="primary"
                            onChange={e =>
                                this._handleChange(
                                    minimumRestTime === '00'
                                        ? '12:00:00'
                                        : '00:00:00',
                                    'minimumRestTime',
                                )
                            }
                            classes={{
                                root: classes.switchRoot,
                            }}
                        />
                        {minimumRestTime !== '00' && (
                            <>
                                <BoatOnNumberField
                                    isDecimal
                                    variant="outlined"
                                    margin="dense"
                                    type="number"
                                    onKeyDown={e => {
                                        if (
                                            e.key === 'e' ||
                                            e.key === 'E' ||
                                            e.key === '-' ||
                                            e.key === '+'
                                        ) {
                                            e.preventDefault()
                                        }
                                    }}
                                    value={minimumRestTime}
                                    onChange={e => {
                                        if (
                                            e.target.value > 0 &&
                                            e.target.value <= 24
                                        )
                                            this._handleChange(
                                                `${e.target.value}:00:00`,
                                                'minimumRestTime',
                                            )
                                    }}
                                    style={{
                                        width: 65,
                                        marginLeft: 30,
                                        marginRight: 10,
                                    }}
                                />
                                <Typography
                                    className={
                                        isMinimumRestTimeSet
                                            ? ''
                                            : classes.disabled
                                    }
                                >
                                    {this.displayText(`hours`)}
                                </Typography>
                            </>
                        )}
                    </div>
                </div>
            </section>
        )
    }

    _renderUserBadge(userLinkId) {
        const { classes, groupMembers } = this.props

        const userLink = groupMembers.find(
            m => m?.id === userLinkId || m?.id === userLinkId,
        )
        const user = userLink?.user ?? userLink?.userSubscribe

        if (user.firstName) {
            return (
                <div className={classes.selectBadge} key={`badge-${user.id}`}>
                    {user.firstName[0] + user.lastName[0]}
                </div>
            )
        } else {
            return (
                <div className={classes.selectBadge} key={`badge-${user.id}`}>
                    <PersonIcon className={classes.userBadgeIcon} />
                </div>
            )
        }
    }

    _renderGroupSelecter() {
        const { classes, groupMembers, workTimeGroups } = this.props
        const { selectedGroupIndex, groups, isInEditMode } = this.state

        if (
            !groupMembers ||
            groupMembers.length === 0 ||
            !workTimeGroups ||
            !Array.isArray(workTimeGroups)
        ) {
            return
        }

        const remainingUsers = groupMembers.filter(
            gm =>
                !workTimeGroups.find(wtg =>
                    wtg.userLinks.find(ul => ul.id === gm.id),
                ),
        )

        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div className={classes.selectContainer}>
                    <Select
                        value={selectedGroupIndex}
                        onChange={e => {
                            this.setState({
                                selectedGroupIndex: e.target.value,
                                isInEditMode: false,
                            })
                        }}
                        className={classes.select}
                        classes={{
                            select: classes.selectInput,
                        }}
                        variant="outlined"
                    >
                        {groups.map((group, groupIndex) => (
                            <MenuItem
                                classes={{
                                    root: classes.selectItemRoot,
                                }}
                                value={groupIndex}
                                key={groupIndex}
                            >
                                {/* Nom du groupe */}
                                <Typography
                                    className={classes.selectItemGroupName}
                                >
                                    {group.byDefault
                                        ? this.displayText('remainingUsers')
                                        : group.groupName}
                                </Typography>

                                {/* Liste des badges des membres du groupe */}
                                {!group.byDefault ? (
                                    <div
                                        className={classes.selectBadgeContainer}
                                    >
                                        {group?.userLinks?.map(
                                            (userLink, index) => {
                                                if (
                                                    index < this.NB_BADGES &&
                                                    userLink?.id
                                                ) {
                                                    return this._renderUserBadge(
                                                        userLink.id,
                                                    )
                                                }
                                            },
                                        )}
                                        {group?.userLinks?.length >
                                            this.NB_BADGES && (
                                            <Typography
                                                className={classes.badgesExtra}
                                            >
                                                +
                                                {group.userLinks.length -
                                                    this.NB_BADGES}
                                            </Typography>
                                        )}
                                    </div>
                                ) : (
                                    <div
                                        className={classes.selectBadgeContainer}
                                    >
                                        {remainingUsers.map(
                                            (userLink, index) => {
                                                if (index < this.NB_BADGES) {
                                                    return this._renderUserBadge(
                                                        userLink.id,
                                                    )
                                                }
                                            },
                                        )}
                                        {remainingUsers.length >
                                            this.NB_BADGES && (
                                            <Typography
                                                className={classes.badgesExtra}
                                            >
                                                +
                                                {remainingUsers.length -
                                                    this.NB_BADGES}
                                            </Typography>
                                        )}
                                    </div>
                                )}
                            </MenuItem>
                        ))}
                    </Select>

                    <BoatOnBlock
                        blockIds={58}
                        page
                        children={
                            <IconButton
                                id={`addGroup`}
                                onClick={this.addGroup}
                                children={
                                    <AddIcon
                                        className={classes.editGroupIcon}
                                    />
                                }
                            />
                        }
                    />

                    {/* Ne pas afficher le bouton modifier pour le groupe par défaut */}
                    {groups[selectedGroupIndex] &&
                        !groups[selectedGroupIndex].byDefault && (
                            <BoatOnBlock
                                blockIds={59}
                                page
                                children={
                                    <IconButton
                                        id={`editGroup`}
                                        onClick={() => {
                                            this.setState({
                                                isInEditMode: true,
                                            })
                                        }}
                                        children={
                                            <EditIcon
                                                className={[
                                                    classes.editGroupIcon,
                                                    isInEditMode
                                                        ? classes.disabledIcon
                                                        : undefined,
                                                ].join(' ')}
                                            />
                                        }
                                        disabled={isInEditMode}
                                    />
                                }
                            />
                        )}
                </div>

                {/* Ne pas afficher le bouton supprimer pour le groupe par défaut */}
                {groups[selectedGroupIndex] &&
                    !groups[selectedGroupIndex].byDefault && (
                        <BoatOnBlock
                            blockIds={64}
                            page
                            children={
                                <Button
                                    variant="text"
                                    startIcon={
                                        <DeleteIcon
                                            className={classes.editGroupIcon}
                                        />
                                    }
                                    style={{ fontSize: 12 }}
                                    onClick={this.deleteGroup}
                                >
                                    {this.displayText('deleteGroup')}
                                </Button>
                            }
                        />
                    )}
            </div>
        )
    }

    _renderUserSelector() {
        const { groups, selectedGroupIndex } = this.state
        const { groupMembers } = this.props
        let _groups = [...groups]

        let userLinks = _groups[selectedGroupIndex].userLinks

        const userValues = userLinks.map(u => {
            return groupMembers.find(m => m?.id === u.id || m?.id === u.id)
        })

        //parcours des groupes pour récupérer les ids des membres des autres groupes
        const unavailableIds = groups.reduce((acc, group) => {
            if (group.byDefault) {
                return acc
            }
            return [...acc, ...group.userLinks.map(u => u.id)]
        }, [])

        return (
            <UserSelector
                onChange={value => {
                    //TODO: a comprendre ce qui doit être fait la
                    _groups[selectedGroupIndex].userLinks = value
                    this.setState({
                        groups: _groups,
                    })
                }}
                value={userValues}
                unavailableIds={unavailableIds}
                label={this.displayText('groupMembers')}
            />
        )
    }

    render() {
        const { groups, selectedGroupIndex, isInEditMode } = this.state
        const { classes, loading } = this.props

        return (
            <div className={classes.container}>
                <section className={classes.subContainer}>
                    <div className={classes.header}>
                        <div>
                            <Typography
                                className={classes.title}
                                component={`h1`}
                                variant={`h1`}
                            >
                                {this.displayText(`title`)}
                            </Typography>
                        </div>
                    </div>
                    {loading ? (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                width: 1000,
                            }}
                        >
                            <img
                                src={anchorLoader}
                                alt="anchor"
                                title="anchor"
                                margin="auto"
                                width={100}
                                height={100}
                            />
                            <Typography className={classes.pageLoadingLabel}>
                                {this.displayText('pageLoadingLabel')}
                            </Typography>
                        </div>
                    ) : (
                        <aside className={classes.body}>
                            <BoatOnAlert
                                severity={`info`}
                                description={this.displayText(`info`)}
                                classes={{
                                    container: classes.alert,
                                }}
                            />
                            {this._renderGroupSelecter()}
                            {isInEditMode &&
                                groups[selectedGroupIndex] &&
                                !groups[selectedGroupIndex].byDefault && (
                                    <>
                                        <TextField
                                            value={
                                                groups[selectedGroupIndex]
                                                    .groupName
                                            }
                                            onChange={e => {
                                                const _groups = [...groups]
                                                _groups[
                                                    selectedGroupIndex
                                                ].groupName = e.target.value

                                                this.setState({
                                                    groups: _groups,
                                                })
                                            }}
                                            variant="outlined"
                                            label={this.displayText(
                                                'groupName',
                                            )}
                                        ></TextField>
                                        {this._renderUserSelector()}
                                    </>
                                )}
                            <div className={classes.content}>
                                <div className={classes.daysContent}>
                                    {this._displayDays()}
                                </div>
                                <Divider
                                    orientation={`horizontal`}
                                    className={classes.desktopDivider}
                                />
                                <div className={classes.hoursContent}>
                                    {this._displayHours()}
                                </div>
                                <Divider
                                    orientation={`horizontal`}
                                    className={classes.desktopDivider}
                                />
                            </div>
                            <div className={classes.footer}>
                                <BoatOnButton
                                    variant={`contained`}
                                    type={`primary`}
                                    onClick={() => {
                                        this.saveSettings()
                                    }}
                                    label={this.displayText(`save`)}
                                    classNameOverride={classes.button}
                                />
                            </div>
                        </aside>
                    )}
                </section>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.authentication.user,
        groupMembers: state.group?.groupsMembers?.linkRGU || [],
        currentGroupId: state.group?.currentGroupId,
        workTimeGroups: state.settings.workTimeSettings,
        loading: state.settings.loading,
        subscriptions: state.group?.groupsMembers?.user?.sub || [],
        groupLoading: state.group.loading,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(WorkTimeSettingPage))
