import React from 'react'
import BoatOnModalCore from '../../../../common/BoatOnModalCore'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import styles from './Styles/NextEventModalCss'
import dictionary from './Dictionary/NextEventModalDico'
import {
    postBOBEvents,
    getNameFromRepair,
    updateStateEvent,
    getCycle,
} from '../../../../../actions/bob.actions'
import BoatOnAlert from '../../../../common/UsefullComponents/BoatOnAlert'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import dateUtils from '@date-io/date-fns'
import LocaleFr from 'date-fns/locale/fr'
import LocaleEn from 'date-fns/locale/en-US'
import CycleSelector from './CycleSelector'
import { InputAdornment, TextField } from '@material-ui/core'

class NextEventModal extends BoatOnModalCore {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {
            event: this.props.event || null,
            error: null,
            lastDate: this.props.event?.delimitedDate?.startDate || new Date(),
            lastHours: this.props.event?.actualDuration || 0,
        }

        this.save = this.save.bind(this)
        this.handleChangeCycleLength = this.handleChangeCycleLength.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }

    save() {
        const { boat } = this.props
        const { event } = this.state
        let {
            eventType,
            checkup,
            delimitedDate,
            actualDuration,
            attachedTo,
            description,
            files,
        } = JSON.parse(JSON.stringify(event))

        if (
            ((delimitedDate && delimitedDate.startDate) ||
                actualDuration !== null) &&
            eventType &&
            (checkup?.checkupType || checkup?.userCheckup)
        ) {
            this.props.dispatch(
                postBOBEvents(boat.id, eventType.id, event.title, {
                    delimitedDate: delimitedDate,
                    checkup: checkup,
                    actualDuration: actualDuration,
                    protocol: this.props?.event?.protocol,
                    attachedTo: attachedTo,
                    description: description,
                }),
            )
            this.props.handleClose()
        } else {
            this.setState({ error: true })
        }
    }

    handleChange(value, name) {
        updateStateEvent(this, { ...value, name: name })
    }

    handleChangeCycleLength(length) {
        const { event, lastDate, lastHours } = this.state
        const cycle = getCycle({ event: event })

        this.handleChange({
            checkup: {
                ...event.checkup,
                userCheckup: {
                    ...event.checkup.userCheckup,
                    cycles: [{ ...cycle, cycleLength: length }],
                },
            },
        })

        if (cycle?.cycleType?.id === 2) {
            this.handleChange({
                actualDuration: lastHours + length,
            })
        } else if (cycle?.cycleType?.id === 1) {
            const date = lastDate ? new Date(lastDate) : new Date()
            this.handleChange({
                delimitedDate: {
                    startDate: new Date(
                        date.setMonth(date.getMonth() + length),
                    ),
                },
            })
        } else if (cycle?.cycleType?.id === 3) {
            const date = lastDate ? new Date(lastDate) : new Date()
            this.handleChange({
                delimitedDate: {
                    startDate: new Date(
                        date.setDate(date.getDate() + length * 7),
                    ),
                },
            })
        }
    }

    _renderDate() {
        const { event, error } = this.state
        const lastDate = new Date(event?.delimitedDate?.startDate)

        return (
            <MuiPickersUtilsProvider
                utils={dateUtils}
                locale={this.getLanguage() === 'FR' ? LocaleFr : LocaleEn}
            >
                <DatePicker
                    autoOk
                    required
                    fullWidth
                    margin={`normal`}
                    variant="inline"
                    label={this.displayText('nextCheckup')}
                    value={
                        event?.delimitedDate?.startDate
                            ? new Date(event.delimitedDate.startDate)
                            : null
                    }
                    error={
                        error && event?.delimitedDate?.startDate === undefined
                    }
                    helperText={
                        error && event?.delimitedDate?.startDate === undefined
                            ? this.displayText('errorDate')
                            : ''
                    }
                    format="dd/MM/yyyy"
                    inputVariant="outlined"
                    onChange={value =>
                        this.handleChange({
                            delimitedDate: {
                                startDate: new Date(value),
                            },
                        })
                    }
                />
            </MuiPickersUtilsProvider>
        )
    }

    _renderHours() {
        const { event, error } = this.state

        return (
            <TextField
                fullWidth
                id="actualCycle"
                variant="outlined"
                label={this.displayText('nextCheckupHour')}
                required
                error={error && event?.actualDuration === undefined}
                helperText={
                    error && event?.actualDuration === undefined
                        ? this.displayText('errorActualCycleLengthLast')
                        : ''
                }
                margin={`normal`}
                value={
                    event?.actualDuration === null ? '' : event?.actualDuration
                }
                onChange={e => {
                    this.handleChange({
                        actualDuration:
                            e.target.value !== ''
                                ? parseInt(e.target.value)
                                : null,
                    })
                }}
                InputProps={{
                    endAdornment: (
                        <>
                            <InputAdornment position="start">
                                {'h'}
                            </InputAdornment>
                        </>
                    ),
                }}
            />
        )
    }

    _renderCycleSelector() {
        const { event, error } = this.state

        return (
            <CycleSelector
                event={event}
                handleChange={this.handleChange}
                error={error}
                handleChangeCycleLength={this.handleChangeCycleLength}
            />
        )
    }

    _renderAlert(cycle) {
        const { event } = this.state
        const name = getNameFromRepair(event, this.getContext())
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: 'numeric',
        }
        const due = event?.delimitedDate?.startDate
            ? new Date(event.delimitedDate.startDate).toLocaleDateString(
                  `fr`,
                  options,
              )
            : null
        const dueHour = event.actualDuration
        return (
            <BoatOnAlert
                severity={`info`}
                description={
                    cycle?.cycleType?.id === 2
                        ? `${this.displayText(
                              `descriptionHourPart1`,
                          )} "${name}" ${this.displayText(
                              `dueHour`,
                          )} ${dueHour || 0} ${this.displayText(
                              `hours`,
                          )}${this.displayText(`descriptionHourPart2`)}`
                        : `${this.displayText(
                              `descriptionPart1`,
                          )} "${name}" ${this.displayText(
                              `due`,
                          )} ${due}${this.displayText(`descriptionPart2`)}`
                }
            />
        )
    }

    _body() {
        let { event } = this.state

        const cycle = getCycle({ event: event })
        if (event?.checkup?.userCheckup) {
            return (
                <>
                    {this._renderCycleSelector()}
                    {cycle?.cycleType?.id === 2
                        ? this._renderHours()
                        : this._renderDate()}
                </>
            )
        } else if (event?.checkup?.checkupType) {
            return (
                <>
                    {this._renderAlert(cycle)}
                    {cycle?.cycleType?.id === 2
                        ? this._renderHours()
                        : this._renderDate()}
                </>
            )
        }
        return <></>
    }

    render() {
        const { title } = this.props

        return (
            <>
                {this._renderTitle(title)}
                {this._renderBody({
                    body: this._body(),
                })}
                {this._renderActions([
                    {
                        label: this.displayText(`dontPlane`),
                        action: this.props.handleClose,
                        type: `text`,
                    },
                    {
                        label: this.displayText(`plane`),
                        action: this.save,
                    },
                ])}
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        boat: state.bob.boat,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(NextEventModal))
