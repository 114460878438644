import React from 'react'
import BoatOnBlock from '../../../../common/Blocks/BoatOnBlock'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import BoatOnComponent from '../../../../common/BoatOnComponent'
import WorkTimePageDico from '../Dictionary/WorkTimePageDico'
import { HiOutlineClipboard } from 'react-icons/all'
import styles from './Styles/WorkTimeBarCss'

class WorkTimeBar extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = WorkTimePageDico
    }

    render() {
        const { classes } = this.props
        const isMobile = window.innerWidth < 600

        if (this.props.open) {
            return (
                <BoatOnBlock
                    blockIds={[52, 51]}
                    onlyOneId={true}
                    children={
                        <div className={classes.saveBottom}>
                            <BoatOnBlock
                                blockIds={51}
                                children={
                                    <Button
                                        className={classes.button}
                                        size="medium"
                                        color="secondary"
                                        variant="contained"
                                        endIcon={
                                            !isMobile ? (
                                                <HiOutlineClipboard />
                                            ) : (
                                                <></>
                                            )
                                        }
                                        onClick={this.props.downloadTimeSheet}
                                    >
                                        {this.displayText('timesheet')}
                                    </Button>
                                }
                            />
                        </div>
                    }
                />
            )
        } else {
            return null
        }
    }
}

export default withStyles(styles)(WorkTimeBar)
