import { trainingConstants } from '../../constants/trainings.constants'

const initialState = {
    loading: 0,
    trainings: null,
}

export default function TrainingsReducer(prevState = initialState, action) {
    switch (action.type) {
        case trainingConstants.REQUEST_TRAINING:
            return {
                ...prevState,
                loading: prevState.loading + 1,
            }
        case trainingConstants.POST_TRAINING_SUCCESS:
        case trainingConstants.PUT_TRAINING_SUCCESS:
            const trainings = (prevState.trainings || []).filter(
                training => training?.id !== action.training?.id,
            )
            console.log(trainings)
            console.log(action.training)
            return {
                ...prevState,
                loading: prevState.loading - 1,
                trainings: [...trainings, action.training],
            }
        case trainingConstants.POST_TRAINING_FAILURE:
        case trainingConstants.PUT_TRAINING_FAILURE:
            return {
                ...prevState,
                loading: prevState.loading - 1,
            }
        case trainingConstants.GET_ALL_TRAINING_SUCCESS:
            return {
                ...prevState,
                loading: prevState.loading - 1,
                trainings: action.trainings,
            }
        case trainingConstants.GET_ALL_TRAINING_FAILURE:
            return {
                ...prevState,
                loading: prevState.loading - 1,
                trainings: [],
            }
        case trainingConstants.DELETE_TRAINING_SUCCESS:
            return {
                ...prevState,
                trainings: (prevState.trainings || []).filter(
                    training => training?.id !== action.trainingId,
                ),
                loading: prevState.loading - 1,
            }

        default:
            return prevState
    }
}
