import React from 'react'
import { connect } from 'react-redux'
import styles from './UserSelectorCss'
import dictionary from './UserSelectorDico'
import BoatOnComponent from '../BoatOnComponent'
import HelpIcon from '@material-ui/icons/Help'
import { Tooltip, withStyles } from '@material-ui/core'
import { groupActions } from '../../../actions'
import BoatOnAutoComplete from '../BoatOnAutoComplete'
import BoatOnModal from '../BoatOnModal'
import NewUserModal from '../../LogbookPage/LogbookNavigationPage/MyUsersPage/NewUserModal'

class UserSelector extends BoatOnComponent {
    constructor(props) {
        super(props)

        this.dictionary = dictionary

        this.state = {
            openModal: null,
        }

        this.conditionAlreadySetRtq = this.conditionAlreadySetRtq.bind(this)
        this.isOptionEqualToValue = this.isOptionEqualToValue.bind(this)
        this.handleOpenModalNewUserRtq = this.handleOpenModalNewUserRtq.bind(
            this,
        )
        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.handleNewUser = this.handleNewUser.bind(this)
        this.getUserName = this.getUserName.bind(this)
        this.filterUsers = this.filterUsers.bind(this)
        this.handleUsersChangeRtq = this.handleUsersChangeRtq.bind(this)
    }

    // Ouvre la modale pour ajouter un Utilisateur
    handleOpenModalNewUserRtq() {
        const { isUserRtq = false } = this.props
        this.setState({ openModal: `newUser`, isUserRtq: isUserRtq })
    }

    // Gère la création d'un nouvel utilisateur par la modale
    async handleNewUser(email, role, boatGroup) {
        const { groupId } = this.props
        await this.props.dispatch(
            groupActions.addUserToGroup(
                groupId,
                role,
                boatGroup,
                email,
                this.context,
            ),
        )
        await this.props.dispatch(groupActions.requestUserGroups(groupId))

        if (this.props.onChange) {
            this.props.onChange({ emailAddUser: email })
        }
        this.handleCloseModal()
    }

    // Gère la fermeture de la modale
    handleCloseModal() {
        this.setState({ openModal: null })
    }

    conditionAlreadySetRtq(option) {
        const { value } = this.props
        return value?.some(element => option?.id && option?.id === element?.id)
    }

    isOptionEqualToValue(option, value) {
        if (
            (value?.userSubscribe?.id &&
                value?.userSubscribe?.id === option?.userSubscribe?.id) ||
            (value?.user?.id && value?.user?.id === option?.user?.id)
        ) {
            return true
        }
        return false
    }

    handleUsersChangeRtq(_, values) {
        if (this.props.onChange) {
            this.props.onChange(values)
        }
    }

    // getUserName(element) {
    //     const { linkedUsers } = this.props
    //     if (element?.userRtq?.id) {
    //         let name = ``
    //         linkedUsers.forEach(link => {
    //             if (link?.user?.id === element?.userRtq?.id) {
    //                 name = `${link?.user?.firstName} ${link?.user?.lastName}`
    //             }
    //         })
    //         return name
    //     } else if (
    //         element?.userSubscribeRtq?.id &&
    //         element?.userSubscribeRtq?.mail
    //     ) {
    //         return element?.userSubscribeRtq?.mail
    //     } else if (element?.user?.id) {
    //         let name = ``
    //         linkedUsers.forEach(link => {
    //             if (link?.user?.id === element?.user?.id) {
    //                 name = `${link?.user?.firstName} ${link?.user?.lastName}`
    //             }
    //         })
    //         return name
    //     } else if (element?.userSubscribe?.id && element?.userSubscribe?.mail) {
    //         return element?.userSubscribe?.mail
    //     }
    //     return null
    // }

    getUserName(element) {
        const { linkedUsers } = this.props

        // Si user-link
        if (element?.user || element?.userSubscribe) {
            if (element?.user?.id) {
                let name = ``
                linkedUsers.forEach(link => {
                    if (link?.user?.id === element?.user?.id) {
                        name = `${link?.user?.firstName} ${link?.user?.lastName}`
                    }
                })
                return name
            } else if (
                element?.userSubscribe?.id &&
                element?.userSubscribe?.mail
            ) {
                return element?.userSubscribe?.mail
            }
        } else if (element.email || element.mail) {
            // Sinon si Utilisateur
            if (element?.id && element.firstName) {
                let name = `${element.firstName} ${element.lastName}`
                return name
            } else if (element?.mail) {
                return element?.mail
            }
        }
        return null
    }

    filterUsers(option, input) {
        const firstLast = option?.user?.id
            ? `${option?.user?.firstName} ${option?.user?.lastName}`
            : null
        const lastFirst = option?.user?.id
            ? `${option?.user?.lastName} ${option?.user?.firstName}`
            : null

        if (
            !input?.inputValue ||
            (option?.user?.id &&
                ((firstLast &&
                    firstLast.toLowerCase().includes(input.inputValue)) ||
                    (lastFirst &&
                        lastFirst.toLowerCase().includes(input.inputValue)))) ||
            (option?.userSubscribe?.id &&
                option?.userSubscribe?.mail
                    .toLowerCase()
                    .includes(input.inputValue.toLowerCase()))
        ) {
            return true
        }
        return false
    }

    render() {
        const {
            boat,
            currentGroupId,
            linkedUsers,
            classes,
            value,
            error,
            label,
            errorMessageMandatory,
            required,
            unavailableIds = [],
            disabled,
            tooltip,
        } = this.props

        //filter the linkedUsers to remove the unavailableIds
        const linkedUsersFiltered = linkedUsers.filter(linkedUser => {
            return !unavailableIds.includes(linkedUser?.id)
        })

        if (currentGroupId === -1) return <></>
        else {
            return (
                <>
                    <BoatOnAutoComplete
                        required={required}
                        autoComplete
                        renderTagCondition={option =>
                            !option.userRtq && option.userSubscribeRtq
                        }
                        getOptionSelected={this.isOptionEqualToValue}
                        helpGreyChip={this.displayText(`helpGreyChip`)}
                        addButton
                        labelAdd={this.displayText('addLabelButton')}
                        onAddButtonClicked={this.handleOpenModalNewUserRtq}
                        margin={`normal`}
                        multiple
                        error={error}
                        helperText={error ? errorMessageMandatory : undefined}
                        value={value}
                        conditionAlready={this.conditionAlreadySetRtq}
                        options={linkedUsersFiltered}
                        label={
                            <>
                                {label}
                                {tooltip && tooltip !== `` && (
                                    <Tooltip
                                        title={tooltip}
                                        classes={{
                                            tooltip: classes.tooltip,
                                        }}
                                    >
                                        <HelpIcon
                                            className={classes.helpIcon}
                                        />
                                    </Tooltip>
                                )}
                            </>
                        }
                        onChange={this.handleUsersChangeRtq}
                        getElementToShow={this.getUserName}
                        getName={this.getUserName}
                        filterOptions={this.filterUsers}
                        inputClass={classes.input}
                        disabled={disabled}
                    />
                    <BoatOnModal
                        openCondition={this.state.openModal}
                        handleClose={this.handleCloseModal}
                        modalCores={{
                            newUser: (
                                <NewUserModal
                                    handleSend={this.handleNewUser}
                                    groupId={this.props.groups?.id}
                                    boats={this.props.boatsGroup}
                                    boatId={boat?.id}
                                />
                            ),
                        }}
                        maxWidth={{
                            newUser: `xs`,
                        }}
                        titles={{
                            newUser: this.displayText(`newUserTitle`),
                        }}
                    />
                </>
            )
        }
    }
}

function mapStateToProps(state) {
    return {
        boat: state.bob.boat,
        loading: state.bob.loading,
        currentGroupId: state.group.currentGroupId,
        linkedUsers: state.group?.groupsMembers?.linkRGU || [],
        role: state.block.selectedGroup.userRole,
        subscriptions: state.group?.groupsMembers?.user?.sub || [],
        groups: state.group.groupsMembers,
        groupId: state.group.currentGroupId,
        boatsGroup: state.group.groupsMembers
            ? state.group.groupsMembers.boats
            : null,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(UserSelector))
