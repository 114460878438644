import { redirectActions } from '../../actions/redirect.actions'
import AppRoute from '../../constants/AppRoute'

export const redirectConfig = {
    ABSENCE: {
        eventFn: redirectActions.getAbsenceByIdForRedirect,
        redirectRoute: AppRoute.LogBook.WorkTime,
        modaleName: 'absence',
        blockId: 54,
    },
    WORKTIME: {
        eventFn: redirectActions.getWorkTimeByIdForRedirect,
        redirectRoute: AppRoute.LogBook.WorkTime,
        modalName: 'workTime',
        blockId: 54,
    },
    REPAIR: {
        eventFn: redirectActions.getBobEventByIdForRedirect,
        modalName: 'editRepair',
        redirectRoute: AppRoute.LogBook.Repair,
        blockId: 20,
    },
    BUDGET: {
        eventFn: redirectActions.getBobEventByIdForRedirect,
        redirectRoute: AppRoute.LogBook.ExpensesAndIncome,
        modalName: '',
        blockId: 7,
    },
}
