import { navigationConstants } from '../../constants/navigation.constants'

const initialState = {
    loading: 0,
    navigations: null,
}

export default function NavigationReducer(prevState = initialState, action) {
    const oldNavigation = prevState.navigations || []

    switch (action.type) {
        case navigationConstants.REQUEST_NAVIGATIONS:
            return {
                ...prevState,
                loading: prevState.loading + 1,
            }
        case navigationConstants.POST_NEW_NAVIGATION:
            return {
                ...prevState,
                navigations: [...oldNavigation, action.navigation],
                loading: prevState.loading - 1,
            }
        case navigationConstants.UPDATE_NAVIGATION:
        case navigationConstants.GET_NAVIGATION_BY_ID:
            const navigations = prevState.navigations
            const index = navigations.findIndex(
                nav => nav.id === action.navigation.id,
            )

            if (index !== -1) {
                navigations[index] = action.navigation

                return {
                    ...prevState,
                    navigations,
                    loading: prevState.loading - 1,
                }
            } else {
                return {
                    ...prevState,
                    navigations: [...navigations, action.navigation],
                    loading: prevState.loading - 1,
                }
            }
        case navigationConstants.GET_NAVIGATIONS_FROM_GROUP:
        case navigationConstants.GET_NAVIGATIONS_FROM_BOAT:
            return {
                navigations: action.navigations,
                loading: prevState.loading - 1,
            }
        case navigationConstants.DELETE_NAVIGATION:
            return {
                navigations: prevState.navigations.filter(
                    nav => nav.id !== action.navigationId,
                ),
                loading: prevState.loading - 1,
            }
        case navigationConstants.ERROR_REQUEST: {
            return {
                loading: prevState.loading - 1,
            }
        }
        default:
            return prevState
    }
}
