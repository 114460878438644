import React from 'react'

import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import BoatOnComponent from '../common/BoatOnComponent'
import styles from './RedirectPageCss'
import AppRoute from '../../constants/AppRoute'
import { history } from '../../helpers'
import ModalConnection from '../common/UsefullComponents/ModalConnection/ModalConnection'
import logo from '../../images/logoBleu.png'
import { Typography } from '@material-ui/core'
import dictionary from './RedirectPageDico'
import { redirectActions } from '../../actions/redirect.actions'
import { redirectConfig } from './RedirectConfig'
import { groupActions, initBobWithBoat } from '../../actions'
import { absencesActions } from '../../actions/bob/absences.actions'

class RedirectPage extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
        this.state = {
            isOkForRedirection: true,
            isModalOpen: false,
            directRedirection: true,
        }
    }

    componentDidMount() {
        // Récupération des paramètres dans l'url
        const params = new URLSearchParams(this.props.location.search)
        const contentType = params.get('content_type')
        const contentId = parseInt(params.get('content_id'))
        const groupId = parseInt(params.get('group_id'))
        const boatId = parseInt(params.get('boat_id'))

        if (contentType && contentId && groupId) {
            // Récupération de la redirection en fonction du type de contenu à ouvrir
            const {
                redirectRoute,
                modalName,
                eventFn,
                blockId,
            } = redirectConfig[contentType.toUpperCase()]
            // Stockage des paramètres dans le store
            this.props.dispatch(
                redirectActions.saveRedirectionParams({
                    contentType,
                    contentId,
                    groupId,
                    boatId,
                    blockId,
                    redirectRoute,
                    modalName,
                    eventFn,
                }),
            )
        } else {
            // Sinon, on redirige vers la page d'accueil
            this.setState({
                isOkForRedirection: false,
            })
        }
    }

    componentDidUpdate(prevProps) {
        const {
            user,
            userLoading,
            redirect,
            event,
            redirectLoading,
            groupMembers,
            groupLoading,
        } = this.props
        const {
            isModalOpen,
            isOkForRedirection,
            directRedirection,
        } = this.state

        // Si l'utilisateur n'est pas connecté
        // On ouvre la modal de connexion
        if (!user && userLoading === 0 && !isModalOpen) {
            return this.setState({
                isModalOpen: true,
                directRedirection: false,
            })
        }

        if (
            redirectLoading > 0 ||
            userLoading > 0 ||
            groupLoading > 0 ||
            groupMembers === null
        ) {
            return
        }

        // Si la redirection en cours est incorrecte
        // On redirige vers la page d'accueil après 5 secondes
        if (!isOkForRedirection) {
            return
            // return setTimeout(() => {
            //     this.historyPush(history, AppRoute.Home)
            // }, 5000)
        }
        // Si l'utilisateur est connecté
        // On redirige vers la page correspondante
        // Si elle n'existe pas, on redirige vers la page d'accueil
        if (user) {
            const currentBoatId = localStorage.getItem('boatIdSelect')
            // Vérfication si l'utilisateur a accès à ce groupe
            const targetGroup =
                redirect.groupId === user.ownGroup.id
                    ? user.ownGroup
                    : user.userGroup.find(g => g.id === redirect.groupId)
                    ? user.userGroup.find(g => g.id === redirect.groupId)
                    : null
            if (targetGroup) {
                const availableBoatsId = targetGroup.boats
                    ? targetGroup.boats.map(b => b.id)
                    : targetGroup.linkRGU.reduce(
                          (acc, { boats }) => [...acc, ...boats.map(b => b.id)],
                          [],
                      )
                if (redirect.groupId !== this.props.currentGroupId) {
                    this.props.dispatch(
                        groupActions.changeCurrentGroup(redirect.groupId),
                    )
                }
                // Si le bateau vers lequel on essaie de rediriger n'est pas dans la liste des bateaux du groupe et qu'il n'est ni un temps de travail ni une absence
                // Erreur
                if (
                    redirect.contentType !== 'ABSENCE' &&
                    redirect.contentType !== 'WORKTIME'
                ) {
                    if (!availableBoatsId.includes(redirect.boatId)) {
                        console.error(
                            "Le bateau n'est pas dans la liste des bateaux du groupe",
                        )
                        return this.setState({
                            isOkForRedirection: false,
                        })
                    }

                    // Si le bateau actuellement sélectionné est différent du bateau vers lequel on essaie de rediriger
                    // Alors on change le bateau actuellement sélectionné
                    if (currentBoatId !== redirect.boatId) {
                        localStorage.setItem('boatIdSelect', redirect.boatId)
                        this.props.dispatch(
                            initBobWithBoat({ user, boatId: currentBoatId }),
                        )
                        // return
                    }
                }

                // Récupération du userLink de l'utilisateur dans le groupe
                const userLink = groupMembers?.linkRGU?.find(
                    l => l?.user?.email === user?.email,
                )

                // Vérification si l'utilisateur a accès à la page vers laquelle on essaie de rediriger
                // Si l'utilisateur n'a pas accès à la page, on redirige vers la page d'accueil
                const userAccessToBlock = userLink?.userRole?.userRoleAccess?.find(
                    block => block?.blocType?.id === redirect.blockId,
                )
                if (!userLink || !userAccessToBlock) {
                    console.error("L'utilisateur n'a pas accès à la page")
                    return this.setState({
                        isOkForRedirection: false,
                    })
                }

                if (redirect.eventFn && event === undefined) {
                    return this.props.dispatch(
                        redirect.eventFn(redirect.contentId),
                    )
                }

                if (
                    redirect.redirectRoute &&
                    event &&
                    event !== null &&
                    redirectLoading === 0
                ) {
                    // Vérfication si l'évènement est accessible par l'utilisateur
                    const userAccessToEvent = this._checkUserAccessToEvent(
                        event,
                        userLink,
                    )

                    // La redirection s'effectue directement si l'utilisateur est déjà connecté
                    // Si l'utilisateur vient de se connecter via la modale, on attend 2 secondes
                    if (userAccessToEvent) {
                        if (directRedirection) {
                            return this.historyPush(
                                history,
                                redirect.redirectRoute,
                            )
                        } else {
                            return setTimeout(() => {
                                this.historyPush(
                                    history,
                                    redirect.redirectRoute,
                                )
                            }, 1000)
                        }
                    } else {
                        return this.setState({
                            isOkForRedirection: false,
                        })
                    }
                }
            }

            // Fallback
            this.setState({
                isOkForRedirection: false,
            })
        }
    }

    _checkUserAccessToEvent(event, userLink) {
        // bobEvent
        if (event?.attachedTo) {
            return event.attachedTo.find(
                a =>
                    a?.user?.id === userLink.user.id ||
                    a?.userRtq?.id === userLink.user.id,
            )
        }

        // absence & worktimes
        return (
            event?.userLinkId === userLink.id ||
            event?.responsibleLink?.id === userLink.id
        )
    }

    /**
     * Renvoi les éléments contenant le statut de la redirection
     * @returns {JSX.Element} the redirection status
     */
    _renderRedirectionStatus = () => {
        const { classes, user, userLoading } = this.props
        const { isOkForRedirection } = this.state

        let message = ''
        if ((user || (!user && userLoading > 0)) && isOkForRedirection) {
            message = this.displayText('redirectionInProgress')
        } else if (!user && userLoading === 0 && isOkForRedirection) {
            message = this.displayText('redirectionWaiting')
        } else {
            message = this.displayText('somethingWentWrong')
        }

        return (
            <>
                <Typography
                    className={[
                        classes.redirectText,
                        !isOkForRedirection ? classes.error : undefined,
                    ].join(' ')}
                >
                    {message}
                </Typography>
                {!isOkForRedirection && (
                    <Typography
                        className={`${classes.redirectText} ${classes.error}`}
                    >
                        {this.displayText('redirectToHomepage')}
                    </Typography>
                )}
            </>
        )
    }

    render() {
        const { classes } = this.props
        const { isModalOpen } = this.state

        return (
            <>
                <div className={classes.page}>
                    <img
                        src={logo}
                        width="180px"
                        alt="Boaton logo"
                        title="Boaton"
                    />
                    {this._renderRedirectionStatus()}
                </div>
                <ModalConnection
                    open={isModalOpen}
                    handleClose={e => {
                        if (e === true) {
                            this.setState({
                                isModalOpen: false,
                            })
                        }
                    }}
                />
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.authentication.user,
        userLoading: state.authentication.loading,
        redirect: state.redirect.params,
        event: state.redirect.event,
        redirectLoading: state.redirect.loading,
        currentGroupId: state.group.currentGroupId,
        groupMembers: state.group.groupsMembers,
        groupLoading: state.group.loading,
        boat: state.bob.boat,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(RedirectPage))
