import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import MyFleet from './MyFleet'
import BoatOnComponent from '../../../common/BoatOnComponent'
import dictionary from './Dictionary/MyFleetListDico'
import styles from './Styles/MyFleetPageCss'
import MyFleetGraphic from './MyFleetGraphic'
import { userActions } from '../../../../actions'
import BoatOnBlock from '../../../common/Blocks/BoatOnBlock'
import catamaranicon from '../../../../images/catamaranicon.svg'
import ModalNoBoat from '../../../common/UsefullComponents/ModalConnection/ModalNoBoat'

class MyFleetPage extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
        this.state = {}
    }
    render() {
        const { classes, subscriptions } = this.props
        const { boat, loading } = this.props
        if (!boat && loading === 0) {
            return (
                <div className={classes.root2}>
                    <img
                        className={classes.backgroundImage}
                        src={catamaranicon}
                        alt="catamaranicon"
                    />
                    <p onClick={this.openModal} className={classes.noBoatText}>
                        {this.displayText(`noBoat`)}
                    </p>
                    {
                        <BoatOnBlock
                            blockIds={18}
                            children={
                                <ModalNoBoat
                                    open={this.state.openModalNoBoat}
                                    handleClose={this.handleClose}
                                />
                            }
                        />
                    }
                </div>
            )
        }
        return (
            <div className={classes.root} key="alerts">
                <BoatOnBlock
                    blockIds={32}
                    children={
                        <div className={classes.main}>
                            <MyFleet />
                        </div>
                    }
                />
                {userActions.checkSubscriptionPage(subscriptions, 9) && (
                    <BoatOnBlock
                        blockIds={32}
                        children={
                            <div className={classes.main}>
                                <MyFleetGraphic />
                            </div>
                        }
                    />
                )}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        subscriptions: state.group?.groupsMembers?.user?.sub || [],
        boat: state.bob.boat,
        loading: state.bob.loading,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(MyFleetPage))
