import React from 'react'
import TextField from '@material-ui/core/TextField'
import { fixIosHeaderBug } from '../../../utils/usefullFunctions'

/**
 * Encapsulation of TextField from mui to convert it in input number
 * @class BoatOnNumberField
 * @extends {BoatOnNumberField}
 *
 * @region ```
 * Customs Props
 * ```
 *
 * @param {Number} min - minimal value possible.
 * @param {Number} max - maximal value possible.
 * @param {Boolean} isDecimal - authorize number with . or ,
 *
 * @region ```
 * Documentation infos
 * ```
 *
 * @date 17/04/2024 - 16:01
 * @author Samuel.C
 */
class BoatOnNumberField extends React.Component {
    constructor(props) {
        super(props)

        this.onKeyDown = this.onKeyDown.bind(this)
        this.onChange = this.onChange.bind(this)
        this.incrementValue = this.incrementValue.bind(this)
        this.decrementValue = this.decrementValue.bind(this)
    }

    onKeyDown(e) {
        const { isDecimal } = this.props;
        console.log('ondown');

        // Autoriser Backspace et les flèches
        if (e.key === 'Backspace' || e.key === 'ArrowUp' || e.key === 'ArrowDown') {
            return; // On ne fait rien pour ces touches
        }

        // Autoriser le '-' seulement si c'est au début ou la chaîne est vide
        if (e.key === '-' && e.target.value.length === 0) {
            return;
        }

        // Vérification des autres caractères
        if (
            (/[^0-9,\.]/.test(e.key) && e.key !== '-') || // Ajout du '-' ici
            ((e.key === '.' || e.key === ',') && /.*(,|\.).*/.test(e.target.value))
        ) {
            e.preventDefault();
        }

        if (!isDecimal && (e.key === '.' || e.key === ',')) {
            e.preventDefault();
        }

        // Gestion des valeurs
        if (e.key === 'ArrowUp') this.incrementValue();
        else if (e.key === 'ArrowDown') this.decrementValue();

        if (this.props.onKeyDown) this.props.onKeyDown(e);
    }

    incrementValue() {
        const { value } = this.props
        let event = {
            target: {
                value: 0,
            },
        }

        if (value !== '') event.target.value = parseFloat(value) + 1

        this.onChange(event)
    }

    decrementValue() {
        const { value } = this.props
        let event = {
            target: {
                value: 0,
            },
        }

        if (value !== '') event.target.value = parseFloat(value) - 1

        this.onChange(event)
    }

    onChange(e) {
        const { onChange, min, max } = this.props
        const value = +e.target.value
        console.log("okey")
        if (min !== undefined && value < min) return
        if (max !== undefined && value > max) return

        if (onChange) onChange(e)
    }

    render() {
        const textFieldProps = { ...this.props }

        //remove error from console
        delete textFieldProps.isDecimal

        return (
            <TextField
                {...textFieldProps}
                onKeyDown={this.onKeyDown}
                inputProps={{
                    inputMode: 'decimal',
                    ...this.props.inputProps,
                }}
                onChange={this.onChange}
                onBlur={() => fixIosHeaderBug()}
            />
        )
    }
}

export default BoatOnNumberField
