import dayjs from 'dayjs'

export const DEFAULT_PAID_VACATIONS_ALLOWED = 25
export const DEFAULT_NB_HOURS_PER_DAY = 8

/**
 * Renvoi l'année à laquelle la période actuelle a commencé
 * Par exemple si la période est du 1er juin au 31 mai, et que nous sommes le 15 mai 2024
 * Alors on renvoi 2023 car la période de 2024 n'a pas encore commencé
 * Et nous sommes donc encore dans la période qui a commencé en 2023
 * @param {*} paidVacationSettings
 */
function getCurrentPeriodYear(paidVacationSettings) {
    const now = dayjs()
    const dateStartPeriod = dayjs()
        .month(paidVacationSettings.monthStartRefPeriod - 1)
        .date(paidVacationSettings.dayStartRefPeriod)

    let currentPeriodYear = dayjs().year()
    if (now.isBefore(dateStartPeriod)) {
        currentPeriodYear--
    }

    return currentPeriodYear
}

/**
 * Renvoi la date de début de la période de calcul des congés payés
 * @param {*} paidVacationSettings
 * @param {*} year (Optionnel) L'année du début de la période. Par défaut, l'année actuelle
 * @returns
 */
function getPeriodDates(paidVacationSettings, year = undefined) {
    let dateStartPeriod = dayjs()
        .month(paidVacationSettings.monthStartRefPeriod - 1)
        .date(paidVacationSettings.dayStartRefPeriod)

    if (year) dateStartPeriod = dateStartPeriod.year(year)

    if (dateStartPeriod.isAfter(dayjs())) {
        dateStartPeriod = dateStartPeriod.subtract(1, 'year')
    }

    return {
        startDate: dateStartPeriod,
        endDate: dateStartPeriod.add(1, 'year').subtract(1, 'day'),
    }
}

function onlyDayAndMonth(date) {
    return dayjs(
        `${date
            .date()
            .toString()
            .padStart(2, `0`)}/${date
            .month()
            .toString()
            .padStart(2, `0`)}`,
        'DD/MM',
    )
}

function getUserLinkAllowedVacations(
    userLinkAllowedVacations,
    paidVacationSettings,
    year = getCurrentPeriodYear(paidVacationSettings),
) {
    const nbPaidVacationAllowed =
        userLinkAllowedVacations?.find(av => av.year === year)
            ?.nbAllowedVacations ??
        paidVacationSettings.nbPaidVacationAllowed ??
        DEFAULT_PAID_VACATIONS_ALLOWED

    return nbPaidVacationAllowed
}

function getRemainingVacations(
    userLink,
    paidVacationsUsed,
    paidVacationSettings,
) {
    if (!paidVacationSettings) return 0

    const nbPaidVacationAllowed = getUserLinkAllowedVacations(
        userLink,
        paidVacationSettings,
    )

    // Calcul du nombre de minutes disponibles
    // En soustrayant les minutes prises des minutes autorisées
    let nbMinutes =
        nbPaidVacationAllowed * 60 * DEFAULT_NB_HOURS_PER_DAY -
        paidVacationsUsed

    return nbMinutes >= 0 ? Math.floor(nbMinutes) : 0
}

function minutesToWorkingDays(minutes) {
    return {
        days: Math.floor(minutes / 60 / DEFAULT_NB_HOURS_PER_DAY),
        hours: Math.floor((minutes / 60) % DEFAULT_NB_HOURS_PER_DAY),
        minutes: Math.floor(minutes % 60),
        totalMinutes: minutes,
        totalHours: minutes / 60,
    }
}

function _getNbMinutesBetweenTimes(startTime, endTime) {
    const start = dayjs()
        .hour(startTime.split(':')[0])
        .minute(startTime.split(':')[1])
    const end = dayjs()
        .hour(endTime.split(':')[0])
        .minute(endTime.split(':')[1])
    return end.diff(start, 'minutes')
}

function _stringToDays(string) {
    return dayjs()
        .hour(string.split(':')[0])
        .minute(string.split(':')[1])
}

function calculateAbsenceDuration(absence, userGroup) {
    let nbMinutes = 0

    // Si le temps d'absence est déjà inscrit dans l'absence, on le renvoi
    if (absence?.nbOfMinutesAbsent && absence?.nbOfMinutesAbsent > 0)
        return absence.nbOfMinutesAbsent

    // Sinon on a besoin des paramètres des temps de travail de l'utilisateur pour calculer le temps d'absence
    if (!userGroup) return nbMinutes

    const start = absence.delimitedDate
        ? absence.delimitedDate.startDate
        : absence.startDate
    const end = absence.delimitedDate
        ? absence.delimitedDate.endDate
        : absence.endDate

    if (absence.absenceTime) {
        const start = _stringToDays(absence.absenceTime.startTime)
        const end = _stringToDays(absence.absenceTime.endTime)
        return end.diff(start, 'minutes')
    }

    const nbDays = dayjs(end).diff(start, 'day') + 1
    for (let i = 0; i < nbDays; i++) {
        const date = new Date(start)
        date.setDate(date.getDate() + i)
        const dayName = date
            .toLocaleDateString('en-US', {
                weekday: 'long',
            })
            .toLowerCase()
        const daySettings = userGroup[`${dayName}Setting`]

        if (daySettings !== null) {
            nbMinutes += _getNbMinutesBetweenTimes(
                daySettings.startTime,
                daySettings.endTime,
            )

            if (daySettings?.breakTimes?.length > 0) {
                for (const breakTime of daySettings.breakTimes) {
                    nbMinutes -= _getNbMinutesBetweenTimes(
                        breakTime.startTime,
                        breakTime.endTime,
                    )
                }
            }
        } else {
            // if (dayName === 'saturday' || dayName === 'sunday')
            //     nbMinutes += 7 * 60
        }
    }
    return nbMinutes
}

/**
 * Filtre les absences pour une période donnée
 * @param {*} absences La liste des absences à filtrer
 * @param {*} year L'année du début de la période
 */
function filterAbsenceForPeriod(absences, year, paidVacationSettings) {
    const { startDate, endDate } = getPeriodDates(paidVacationSettings, year)

    return absences.filter(absence => {
        return dayjs(absence.delimitedDate.startDate).isBetween(
            startDate,
            endDate,
        )
    })
}

function calculatePaidVacations(
    userLink,
    groupPaidVacationsAllowed,
    absences,
    userWorkTimeGroup,
    paidVacationSettings,
) {
    const userAllowedVacations = groupPaidVacationsAllowed.find(
        uav => uav.userLinkId === userLink.id,
    )?.value

    if (!userAllowedVacations) return []

    const data = userAllowedVacations
        // Filtre pour traiter soit l'année en cours soit toutes les années si le cumul est autorisé
        .filter(av => {
            return (
                paidVacationSettings.allowPaidVacationAddition ||
                av.year === getCurrentPeriodYear(paidVacationSettings)
            )
        })
        .map((av, i) => {
            const absencesForYear = filterAbsenceForPeriod(
                absences,
                av.year,
                paidVacationSettings,
            ).filter(
                // Uniquement les event validés et de type congés payés
                a => a.absenceStatus.id === 2 && a.absenceReasonType.id === 1,
            )

            return {
                year: av.year,
                absences: absencesForYear,
                allowed: av.nbAllowedVacations * 60 * DEFAULT_NB_HOURS_PER_DAY,
                absenceTime: absencesForYear.reduce((acc, a) => {
                    return acc + calculateAbsenceDuration(a, userWorkTimeGroup)
                }, 0),
            }
        })

    return data
}

export default {
    getCurrentPeriodYear,
    getPeriodDates,
    onlyDayAndMonth,
    getUserLinkAllowedVacations,
    getRemainingVacations,
    minutesToWorkingDays,
    calculateAbsenceDuration,
    filterAbsenceForPeriod,
    calculatePaidVacations,
}
